<template>
  <div class="IndustryDistributionMap">
    <chart-Common
      title="产业链空间分布"
      :showChangeBtn="true"
      :tabsList="navAllList"
      @showChartType="showChartType"
    >
      <el-container class="container">
        <el-aside class="left_aside">
          <div class="left_top">
            <chart-Common title="9+2+N 产业链">
              <div class="top_content">
                <div class="left_sel1">
                  <span class="date-container">产业链选择</span>
                  <div class="select-op-container">
                    <el-select
                      v-model="value1"
                      placeholder="请选择"
                      @change="changeQuarter"
                    >
                      <el-option
                        v-for="item in options1"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
                <div class="left_sel2">
                  <el-row :gutter="5">
                    <el-col :span="8">
                      <div class="select-op-container left_op">
                        <el-select
                          v-model="value2_2"
                          placeholder="请选择"
                          @change="changeQuarter"
                        >
                          <el-option
                            v-for="item in options2"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </div>
                    </el-col>
                    <el-col :span="16">
                      <div class="select-op-container right_op">
                        <el-select
                          v-model="value3"
                          placeholder="请选择"
                          @change="changeChainType"
                        >
                          <el-option
                            v-for="item in options3"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </chart-Common>
          </div>
          <div class="left_bottom" v-if="value3 != '所有'">
            <chart-Common title="电解液产业链">
              <div class="switchBtn">
                <el-button
                  size="mini"
                  @click="$router.push('/main/newChain/chainDetail')"
                  class="newChainBtnStyle"
                  >返回</el-button
                >
                <el-button
                  size="mini"
                  @click="showAllEnterprise"
                  class="newChainBtnStyle"
                  >所有企业</el-button
                >
              </div>

              <div class="tabBar">
                <div
                  :class="
                    selectedType == 0 ? 'itemOne actived_itemOne' : 'itemOne'
                  "
                  @click="selectItem(0)"
                >
                  <span>上游</span>
                </div>
                <div
                  :class="
                    selectedType == 1 ? 'itemOne actived_itemOne' : 'itemOne'
                  "
                  @click="selectItem(1)"
                >
                  <span>中游</span>
                </div>
                <div
                  :class="
                    selectedType == 2 ? 'itemOne actived_itemOne' : 'itemOne'
                  "
                  @click="selectItem(2)"
                >
                  <span>下游</span>
                </div>
              </div>
              <div class="spera1">
                <span> 区内企业 </span>
              </div>
              <div class="itemList">
                <div class="item" @click="setEnterpriseInfo">
                  <p>深圳市坪山区-</p>
                  <p>深圳新宙邦科技股份有限公司</p>
                </div>
              </div>
              <div class="spera1">
                <span> 区外企业 </span>
              </div>
              <div class="itemList">
                <div
                  class="item"
                  v-for="(item, index) in outsideCompanyList"
                  :key="index"
                >
                  <p>{{ item.address }}-</p>
                  <p>{{ item.name }}</p>
                </div>
              </div>
            </chart-Common>
          </div>
        </el-aside>
        <el-main>
          <!-- <Map ref="map" :type="industryType"></Map> -->
          <div id="map"></div>
        </el-main>
        <el-aside class="right_aside" v-if="showEnterpriseInfo">
          <chart-common title="企业使用空间信息">
            <div class="spera1">
              <span>{{ selectedName }}</span>
              <div class="btn" @click="centerDialogVisible = true">
                企业信息
              </div>
            </div>
            <div class="itemList">
              <ul>
                <li v-for="(item, index) in itemList" :key="index">
                  <div class="title">
                    <img
                      src="../../assets/images/industryMap/left/03@2x.png"
                      alt=""
                    />
                    <span>{{ item.name }}</span>
                  </div>
                  <p>{{ '--' }}</p>
                </li>
              </ul>
            </div>
            <div class="linkBtnList">
              <el-button
                @click="$router.push('/main/IndustryLanding')"
                style="background: rgba(86, 130, 163, 0.5); color: #fff"
                >关联用地信息</el-button
              >
              <el-button
                @click="$router.push('/main/industryHousing')"
                style="background: rgba(86, 130, 163, 0.5); color: #fff"
                >关联用房信息</el-button
              >
            </div>
          </chart-common>
        </el-aside>
      </el-container>
    </chart-Common>

    <el-dialog
      :visible.sync="centerDialogVisible"
      width="1340px"
      height="40%"
      center
      :close-on-click-modal="false"
    >
      <div class="modal-line-chart">
        <div class="close-btn">
          <img
            @click="centerDialogVisible = false"
            src="@/assets/images/charts/x.png"
            alt=""
          />
        </div>
        <chart-Common title="企业基础信息">
          <div class="content">
            <div class="table">
              <div class="itemOne">
                <div class="table_head"><span> 统一社会信用代码</span></div>
                <div class="table_content">
                  <span>91440300736252008C</span>
                </div>
              </div>
              <div class="itemOne">
                <div class="table_head"><span> 成立日期</span></div>
                <div class="table_content">
                  <span>2002/2/19</span>
                </div>
              </div>
              <div class="itemOne">
                <div class="table_head"><span> 外观专利（件）</span></div>
                <div class="table_content"><span>--</span></div>
              </div>
              <div class="itemOne">
                <div class="table_head"><span> 企业类别</span></div>
                <div class="table_content">
                  <span>{{ row.企业类型 || '--' }}</span>
                </div>
              </div>
              <div class="itemOne">
                <div class="table_head"><span> 法定代表人</span></div>
                <div class="table_content">
                  <span>范家威</span>
                </div>
              </div>
              <div class="itemOne">
                <div class="table_head"><span> 实用新型专利（件）</span></div>
                <div class="table_content"><span>--</span></div>
              </div>
              <div class="itemOne">
                <div class="table_head"><span> 地址</span></div>
                <div class="table_content">
                  <span
                    >深圳市坪山区坪山街道六联社区昌业路9号新宙邦科技大厦1901</span
                  >
                </div>
              </div>
              <div class="itemOne">
                <div class="table_head"><span> 联系电话</span></div>
                <div class="table_content">
                  <span>15013569982</span>
                </div>
              </div>
              <div class="itemOne">
                <div class="table_head"><span> 企业融资（次）</span></div>
                <div class="table_content"><span>--</span></div>
              </div>
              <div class="itemOne">
                <div class="table_head"><span> 街道</span></div>
                <div class="table_content"><span>坪山街道</span></div>
              </div>
              <div class="itemOne">
                <div class="table_head"><span> 登记机关</span></div>
                <div class="table_content"><span>--</span></div>
              </div>
              <div class="itemOne">
                <div class="table_head">
                  <span> 企业融资总金额 (万元)</span>
                </div>
                <div class="table_content"><span>--</span></div>
              </div>
              <div class="itemOne">
                <div class="table_head"><span> 参保人数</span></div>
                <div class="table_content">
                  <span>556</span>
                </div>
              </div>
              <div class="itemOne">
                <div class="table_head"><span> 产值 (亿元)</span></div>
                <div class="table_content"><span>--</span></div>
              </div>
              <div class="itemOne">
                <div class="table_head"><span> 企业对外融资（次）</span></div>
                <div class="table_content"><span>--</span></div>
              </div>
              <div class="itemOne">
                <div class="table_head"><span> 认缴资本总数</span></div>
                <div class="table_content">
                  <span>74245.0163万元人民币</span>
                </div>
              </div>
              <div class="itemOne">
                <div class="table_head"><span> 上一年税收金额</span></div>
                <div class="table_content"><span>--</span></div>
              </div>
              <div class="itemOne">
                <div class="table_head">
                  <span> 企业对外投资总金额 (万元)</span>
                </div>
                <div class="table_content"><span>--</span></div>
              </div>
              <div class="itemOne">
                <div class="table_head"><span> 注册状态</span></div>
                <div class="table_content">
                  <span>存续（在营、开业、在册）</span>
                </div>
              </div>
              <div class="itemOne">
                <div class="table_head"><span> 专利申请(件)</span></div>
                <div class="table_content"><span>--</span></div>
              </div>
              <div class="itemOne">
                <div class="table_head"><span> 行业类型</span></div>
                <div class="table_content">
                  <span>{{ row.所属行业 || '--' }}</span>
                </div>
              </div>
            </div>
            <div class="right_bottom">
              <div class="tabs">
                <img
                  src="../../assets/images/enterpriseInfo/title2.png"
                  alt=""
                />
                <div class="tabs_list">
                  <ul>
                    <li
                      :class="{ actived: activedTabs == 1 }"
                      @click="selectTopTabs(1)"
                    >
                      企业画像
                    </li>
                    <li
                      :class="{ actived: activedTabs == 2 }"
                      @click="selectTopTabs(2)"
                    >
                      企业对标
                    </li>
                  </ul>
                </div>
              </div>
              <div class="rb_content">
                <div class="pane">
                  <div class="rb_tabs">
                    <div
                      :class="
                        zjType == 0 ? 'rb_left zjActviedClass' : 'rb_left'
                      "
                      @click="selectType(0)"
                    >
                      <img
                        :src="
                          zjType == 0
                            ? require('../../assets/images/industryHousing/typeLeft_actived.png')
                            : require('../../assets/images/industryHousing/typeLeft.png')
                        "
                        alt=""
                      />
                      <span>2022年</span>
                    </div>
                    <div
                      :class="
                        zjType == 1 ? 'rb_center zjActviedClass' : 'rb_center'
                      "
                      @click="selectType(1)"
                    >
                      <img
                        :src="
                          zjType == 1
                            ? require('../../assets/images/industryHousing/typeCenter_actived.png')
                            : require('../../assets/images/industryHousing/typeCenter.png')
                        "
                        alt=""
                      />
                      <span>2021年</span>
                    </div>
                    <div
                      :class="
                        zjType == 2 ? 'rb_right zjActviedClass' : 'rb_right'
                      "
                      @click="selectType(2)"
                    >
                      <img
                        :src="
                          zjType == 2
                            ? require('../../assets/images/industryHousing/typeRight_actived.png')
                            : require('../../assets/images/industryHousing/typeRight.png')
                        "
                        alt=""
                      />
                      <span>2020年</span>
                    </div>
                  </div>
                  <!-- <div class="searchBar">
                  <el-input
                    placeholder="搜索企业名"
                    v-model="input2"
                    class="input-with-select"
                  >
                    <el-button
                      slot="append"
                      icon="el-icon-search"
                      class="appendBtn"
                    ></el-button>
                  </el-input>
                </div> -->
                </div>
                <div class="picture" v-if="activedTabs == 1">
                  <radarChart echartId="radar1"></radarChart>
                </div>

                <div class="marking" v-if="activedTabs == 2">
                  <div class="legend">
                    <div class="item">
                      <div class="pic"></div>
                      <span>对标企业</span>
                    </div>
                    <div class="item">
                      <div class="pic2"></div>
                      <span>A企业</span>
                    </div>
                  </div>
                  <div class="barchart">
                    <bar :echartId="k" v-for="k in 6"></bar>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </chart-Common>
      </div>
    </el-dialog>
  </div>
</template>
<script>
const cityOptions = [
  '平湖',
  '宝龙',
  '坪地',
  '坂田',
  '园山',
  '南湾',
  '龙岗',
  '龙城',
  '横岗',
  '吉华',
  '布吉'
]
// import Map from '@/components/map'
import L from 'leaflet'
import '@supermap/iclient-leaflet'
import 'leaflet-editable'
import 'leaflet.markercluster'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'
import chartCommon from '@/components/chartCommon/index'
import pieChart from '@/components/charts/pie'
import pieBChart from '@/components/charts/pieB'
import bar from '@/components/enterpriseInfo/bar'
import radarChart from '@/components/charts/radar'
export default {
  components: {
    // Map,
    chartCommon,
    pieChart,
    pieBChart,
    radarChart,
    bar
  },
  data () {
    return {
      map: null,
      showEnterpriseInfo: false,
      selectedName: '深圳新宙邦科技股份有限公司',
      row: {},
      activedTabs: 1,
      zjType: 0,
      centerDialogVisible: false,
      navAllList: [{ name: '返回产业链' }],
      itemList: [
        { name: '街道社区/网格编号', value: '--', key: 'street' },
        { name: '生产地址', value: '--', key: 'community' },
        { name: '用地属性', value: '--', key: 'gridNumber' },
        { name: '所在园区', value: '--', key: 'gridArea' },
        { name: '租金水平', value: '--', key: 'jzwNum' },
        { name: '厂房面积', value: '--', key: 'jzwArea' },
        { name: '厂房层高', value: '--', key: 'ratio' },
        { name: '厂房承重', value: '--', key: 'developDensity' },
        { name: '研发办公面积', value: '--', key: 'confirmedArea' }
      ],
      //  =============================坪山====================================
      map_gaoxin: `${window.BASE_URL.superMapUrl}/iserver/services/map-ps/rest/maps/gxjsqy@ps`,
      map_sishang: `${window.BASE_URL.superMapUrl}/iserver/services/map-ps/rest/maps/gyqy@ps`,
      map_zjtx1: `${window.BASE_URL.superMapUrl}/iserver/services/map-ps/rest/maps/zjtx_gd@ps`,
      map_zjtx2: `${window.BASE_URL.superMapUrl}/iserver/services/map-ps/rest/maps/zjtx_gj@ps`,
      map_glqy1: `${window.BASE_URL.superMapUrl}/iserver/services/map-ps/rest/maps/glqy_jsyj@ps`,
      map_glqy2: `${window.BASE_URL.superMapUrl}/iserver/services/map-ps/rest/maps/glqy_gjcp@ps`,
      map_glqy5: `${window.BASE_URL.superMapUrl}/iserver/services/map-ps/rest/maps/glqy_qyjs@ps`,
      map_glqy3: `${window.BASE_URL.superMapUrl}/iserver/services/map-ps/rest/maps/glqy_gcjs@ps`,
      map_glqy4: `${window.BASE_URL.superMapUrl}/iserver/services/map-ps/rest/maps/glqy_gcyj@ps`,
      projectNum: 23,
      selectedType: 1,
      selectedNav: 'bottom',
      industryType: '0',
      checkAll: false,
      checkedCities: ['上海', '北京'],
      cities: cityOptions,
      isIndeterminate: true,
      options1: [
        { label: '智能网联汽车', value: '智能网联汽车' },
        { label: '半导体与集成电路', value: '半导体与集成电路' },
        { label: '智能终端', value: '智能终端' },
        { label: '现代时尚', value: '现代时尚' },
        { label: '激光与增材制造', value: '激光与增材制造' },
        { label: '新材料', value: '新材料' },
        { label: '高端医疗器械', value: '高端医疗器械' },
        { label: '生物医药', value: '生物医药' },
        { label: '大健康', value: '大健康' },
        { label: '脑科学与类脑智能', value: '脑科学与类脑智能' },
        { label: '细胞与基因', value: '细胞与基因' }
      ],
      options2: [
        { label: '所有', value: '所有' },
        { label: '上游', value: '上游' },
        { label: '中游', value: '中游' },
        { label: '下游', value: '下游' }
      ],
      options3: [
        { label: '所有', value: '所有' },
        { label: '电解液', value: '电解液' },
        { label: '负极材料', value: '负极材料' },
        { label: '正极材料', value: '正极材料' },
        { label: '其他金属', value: '其他金属' },
        { label: '隔膜', value: '隔膜' },
        { label: '碳酸理', value: '碳酸理' }
      ],
      navMenuList: [
        { name: '电子信息技术', num: 190 },
        { name: '生物与新医药技术', num: 123 },
        { name: '新材料技术', num: 80 },
        { name: '高技术服务业', num: 21 },
        { name: '新能源及节能技术', num: 34 },
        { name: '资源与环境技术', num: 29 },
        { name: '先进制造与自动化', num: 209 }
      ],
      leftList: [
        { name: '国家级专精特新小巨人企业名单', num: 9 },
        { name: '广东省专精特新企业名单', num: 123 }
      ],
      rightList: [
        { name: '规上工业', num: 703 },
        { name: '房地产企业和资质等级以上建筑业', num: '--' },
        { name: '限额以上批零住餐', num: '--' },
        { name: '规模以上服务业', num: '--' }
      ],
      bottomList: [
        { name: '电子信息技术', num: 190 },
        { name: '生物与新医药技术', num: 123 },
        { name: '新材料技术', num: 80 },
        { name: '高技术服务业', num: 21 },
        { name: '新能源及节能技术', num: 34 },
        { name: '资源与环境技术', num: 29 },
        { name: '先进制造与自动化', num: 209 }
      ],
      topList: [
        { name: '国家单项冠军产品企业名单', num: 4 },
        { name: '国家级企业技术中心', num: 7 },
        { name: '国家级众创空间', num: 1 },
        { name: '国家级孵化器', num: 1 },
        { name: '深圳技术大学校企合作研究院清单', num: 12 },
        { name: '广东省工程技术研究中心（省科创委主管）', num: 47 },
        { name: '深圳市工程技术中心（市科创委主管）', num: 8 },
        { name: '深圳市工程研究中心（市发改委主管）', num: 13 },
        { name: '深圳市企业技术中心（市工信局主管）', num: 15 },
        { name: '坪山区重点研发平台名单', num: 6 },
        { name: '比亚迪集团研发机构', num: 11 }
      ],
      outsideCompanyList: [
        { name: '广州天赐高新材料股份有限公司', address: '广州市' },
        { name: '宁波杉杉股份有限公司', address: '宁波市' },
        { name: '延安必康制药股份有限公司', address: '延安市' },
        { name: '浙江万盛股份有限公司', address: '台州市' },
        { name: '江苏国泰国际集团股份有限公司', address: '苏州市' },
        { name: '湖北振华化学股份有限公司', address: '黄石市' }
      ],
      options: [
        {
          label: '一季度',
          value: 1
        },
        {
          label: '二季度',
          value: 2
        }
      ],
      value: '一季度',
      value1: '智能网联汽车',
      value2_2: '所有',
      value3: '所有',
      allChartLegend: {
        chartA: {
          currentChartSelect: 'All',
          optionsTitle: '+500亿元\n同比+12%'
        },
        chartB: {
          currentChartSelect: 'All',
          optionsTitle: '300家\n120亿元'
        },
        chartC: {
          currentChartSelect: 'All',
          optionsTitle: '+500亿元\n同比+12%'
        },
        chartD: {
          currentChartSelect: 'All',
          optionsTitle: '23家\n本季度+3家'
        }
      },
      pieChartSelect: [
        {
          value: 744,
          color: '#149AFE',
          name: '绿色低碳产业产值',
          text: '绿色低碳产业产值',
          percent: '23.80'
        },
        {
          value: 520.3,
          color: '#4EC8D3',
          name: '新一代信息技术增长产值',
          text: '新一代信息技术增长产值',
          percent: '11.70'
        },
        {
          value: 210.26,
          color: '#E1D14F',
          name: '生物医药和健康产业产值',
          text: '生物医药和健康产业产值',
          percent: '1.50'
        }
      ],
      pieChartSelectB: [
        {
          color: '#149AFE',
          text: '发明专利',
          name: '发明专利',
          value: '1251'
        },
        {
          color: '#4EC8D3',
          text: '实用新型专利',
          name: '实用新型专利',
          value: '5341'
        },
        {
          color: '#E1D14F',
          text: '外观设计专利',
          name: '外观设计专利',
          value: '1725'
        }
      ],
      chartCTitle: {
        num: '1474.6',
        changeNum: '+15.80'
      },
      sishang: '',
      gaoxin: '',
      resultLayer: '',
      querygroup: '',
      querygroup2: '',
      zjtxgroupLayer: '',
      glqygroupLayer: '',
      cluster: '',
      value2: '',
      jiedao: '',
      yuanqu: '',
      build: '',
      markerList_all: [
        {
          x: '22.6902457',
          y: '114.3601895',
          content: '深圳佛吉亚汽车部件有限公司'
        },
        { x: '22.76503784', y: '114.4167228', content: '深圳开沃汽车有限公司' },
        {
          x: '22.72786061',
          y: '114.3880629',
          content: '深圳市鹏大光电技术有限公司'
        },
        {
          x: '22.72445556',
          y: '114.3954547',
          content: '深圳市凯中精密技术股份有限公司'
        },
        {
          x: '22.72789628',
          y: '114.4047462',
          content: '深圳市金威源科技股份有限公司'
        },
        {
          x: '22.66597687',
          y: '114.2965368',
          content: '深圳中鼎工业科技有限公司'
        },
        {
          x: '22.54845664',
          y: '114.0645518',
          content: '深圳市新嘉拓自动化技术有限公司'
        },
        {
          x: '22.72276296',
          y: '114.398453',
          content: '深圳巴斯巴科技发展有限公司'
        },
        {
          x: '22.75884757',
          y: '114.4074586',
          content: '深圳博识诊断技术有限公司'
        },
        {
          x: '22.72186365',
          y: '114.4037175',
          content: '深圳市尚水智能设备有限公司'
        },
        {
          x: '22.76062428',
          y: '114.3838726',
          content: '深圳市山木新能源科技股份有限公司'
        },
        {
          x: '22.75407653',
          y: '114.4085399',
          content: '深圳市浩能科技有限公司'
        },
        {
          x: '22.7162443',
          y: '114.4051031',
          content: '深圳市曼恩斯特科技股份有限公司'
        },
        {
          x: '22.67143107',
          y: '114.2889277',
          content: '深圳市展业精密塑胶有限公司'
        },
        {
          x: '22.76096612',
          y: '114.3705819',
          content: '深圳市三和朝阳科技股份有限公司'
        },
        {
          x: '22.69666959',
          y: '114.3539069',
          content: '比亚迪汽车工业有限公司'
        },
        {
          x: '22.68908',
          y: '114.377549',
          content: '深圳新宙邦科技股份有限公司'
        }
      ],
      markerList_djy: [
        {
          x: '23.1305039',
          y: '113.5473214',
          content: '广州天赐高新材料股份有限公司'
        },
        { x: '29.81551025', y: '121.5545657', content: '宁波杉杉股份有限公司' },
        {
          x: '36.64672391',
          y: '109.503478',
          content: '延安必康制药股份有限公司'
        },
        { x: '28.84255097', y: '121.1573394', content: '浙江万盛股份有限公司' },
        {
          x: '31.87755934',
          y: '120.5739746',
          content: '江苏国泰国际集团股份有限公司'
        },
        {
          x: '30.1996829',
          y: '115.2086159',
          content: '湖北振华化学股份有限公司'
        },
        {
          x: '22.68850507',
          y: '114.34138',
          content: '深圳新宙邦科技股份有限公司'
        }
      ]
    }
  },
  beforeRouteEnter (to, from, next) {
    if (
      from.path == '/main/IndustryLanding' ||
      from.path == '/main/industryHousing'
    ) {
      next(vm => {
        vm.value3='电解液'
        setTimeout(() => {
          vm.map.setZoom(16)
          vm.setEnterpriseInfo()
        }, 500);
      })
    }
    next()
  },
  created () {
    // this.selectNav('bottom')
    // this.changeQuarter(1)
  },
  mounted () {
    if (this.$route.query && this.$route.query.key) {
      this.value3 = '电解液'
    }
    this.init()
    if(this.$route.query.key=='qy') {
        this.setEnterpriseInfo()
      }
    window.toParkDetail = this.toParkDetail
  },
  methods: {
    showAllEnterprise () {
      if (this.map.hasLayer(this.querygroup)) {
        this.querygroup.clearLayers()
      }
      this.showEnterpriseInfo = false
      for (var i = 0; i < this.markerList_djy.length; i++) {
        var popupContent1 = `<div class="popup">
              <div class="echart-container">
                <div class="cont_title">${this.markerList_djy[i].content}</div>
                <div class="cont_btn" onclick="toParkDetail('${this.markerList_djy[i].content}')"><a>详情>>></a></div>
              </div>
            </div>`
        L.marker([this.markerList_djy[i].x, this.markerList_djy[i].y])
          .addTo(this.querygroup)
          .bindPopup(popupContent1)
        // .openPopup()
      }
      this.map.flyTo([22.68850507, 114.34138], 5)
      // .openPopup()
    },
    setEnterpriseInfo () {
      if (this.map.hasLayer(this.querygroup)) {
        this.querygroup.clearLayers()
      }
      this.showEnterpriseInfo = true
      var popupContent1 = `<div class="popup">
              <div class="echart-container">
                <div class="cont_title">深圳新宙邦科技股份有限公司</div>
                <div class="cont_btn" onclick="toParkDetail('深圳新宙邦科技股份有限公司')"><a>详情>>></a></div>
              </div>
            </div>`
      L.marker([22.68850507, 114.34138])
        .addTo(this.querygroup)
        .bindPopup(popupContent1)
        .openPopup()
      this.map.flyTo([22.68850507, 114.34138], 16)
    },
    toParkDetail (name) {
      this.centerDialogVisible = true
      this.selectedName = name
    },
    selectTopTabs (val) {
      this.activedTabs = val
    },
    selectType (type) {
      this.zjType = type
    },
    showChartType () {
      this.$router.push('/main/IndustrialChain')
    },
    changeChainType (e) {
      if (this.map.hasLayer(this.querygroup)) {
        this.querygroup.clearLayers()
      }
      if (e == '电解液') {
        for (var i = 0; i < this.markerList_djy.length; i++) {
          var popupContent1 = `<div class="popup">
              <div class="echart-container">
                <div class="cont_title">${this.markerList_djy[i].content}</div>
                <div class="cont_btn" onclick="toParkDetail('${this.markerList_djy[i].content}')"><a>详情>>></a></div>
              </div>
            </div>`
          L.marker([this.markerList_djy[i].x, this.markerList_djy[i].y])
            .addTo(this.querygroup)
            .bindPopup(popupContent1)
          // .openPopup()
        }
        this.map.flyTo([22.68850507, 114.34138], 5)
      } else if (e == '所有') {
        this.showEnterpriseInfo = false
        this.map.flyTo([22.68850507, 114.34138], 11)
        for (var k = 0; k < this.markerList_all.length; k++) {
          var popupContent = `<div class="popup">
              <div class="echart-container">
                <div class="cont_title">${this.markerList_all[k].content}</div>
                <div class="cont_btn" onclick="toParkDetail('${this.markerList_all[k].content}')"><a>详情>>></a></div>
              </div>
            </div>`
          L.marker([this.markerList_all[k].x, this.markerList_all[k].y])
            .addTo(this.querygroup)
            .bindPopup(popupContent)
          // .openPopup()
        }
      }
    },
    initModal () {},
    selectItem (type) {
      this.selectedType = type
    },
    init () {
      const vm = this
      // eslint-disable-next-line no-undef
      var tianditu = L.supermap.tiandituTileLayer({
        layerType: 'img',
        url: 'http://t0.tianditu.gov.cn/img_c/wmts?tk=c0ae855abbcd4b33d10e11e3af914aa7'
      })
      var labelMap = L.supermap.tiandituTileLayer({
        isLabel: true,
        url: 'http://t{s}.tianditu.com/{layer}_{proj}/wmts?tk=c0ae855abbcd4b33d10e11e3af914aa7'
      })
      var tdtYx = L.layerGroup([tianditu, labelMap])
      // var qu = L.supermap.tiledMapLayer(this.map_qu)
      this.jiedao = L.supermap.tiledMapLayer(this.map_jd)
      this.yuanqu = L.supermap.tiledMapLayer(this.map_yuanqu)
      this.build = L.supermap.tiledMapLayer(this.map_build)
      this.gaoxin = L.supermap.tiledMapLayer(this.map_gaoxin)
      this.sishang = L.supermap.tiledMapLayer(this.map_sishang)
      var zjtx1 = L.supermap.tiledMapLayer(this.map_zjtx1)
      var zjtx2 = L.supermap.tiledMapLayer(this.map_zjtx2)
      var glqy1 = L.supermap.tiledMapLayer(this.map_glqy1)
      var glqy2 = L.supermap.tiledMapLayer(this.map_glqy2)
      var glqy3 = L.supermap.tiledMapLayer(this.map_glqy3)
      var glqy4 = L.supermap.tiledMapLayer(this.map_glqy4)
      var glqy5 = L.supermap.tiledMapLayer(this.map_glqy5)
      this.map = L.map('map', {
        preferCanvas: true,
        crs: L.CRS.EPSG4326,
        center: [22.7152291384486, 114.347931662042],
        // center: [[22.7152291384486,114.347931662042]],
        maxZoom: 16,
        zoom: 11,
        // layers: [tdtYx, qujie,gaoxin],
        layers: [tdtYx],
        zoomControl: false,
        control: ['zoomControl']
        //  maxBounds: [
        //   [ 22.65, 114.27],
        //   [22.78 , 114.35167101400009]
        // ]
      })
      this.querygroup = L.layerGroup().addTo(this.map)

      //   this.gaoxin.addTo(this.querygroup)
      // L.control.layers(baseMaps, overlayMaps).addTo(this.map)
      if (this.$route.query && this.$route.query.key) {
        // 电解液进来的展示电解液企业
        this.changeChainType('电解液')
      } else {
        for (var i = 0; i < this.markerList_all.length; i++) {
          var popupContent = `<div class="popup">
              <div class="echart-container">
                <div class="cont_title">${this.markerList_all[i].content}</div>
                <div class="cont_btn" onclick="toParkDetail('${this.markerList_all[i].content}')"><a>详情>>></a></div>
              </div>
            </div>`
          L.marker([this.markerList_all[i].x, this.markerList_all[i].y])
            .addTo(this.querygroup)
            .bindPopup(popupContent)
          // .openPopup()
        }
      }

      this.querygroup2 = L.layerGroup().addTo(this.map)
      this.zjtxgroupLayer = L.layerGroup([zjtx1, zjtx2])
      this.glqygroupLayer = L.layerGroup([glqy1, glqy2, glqy3, glqy4, glqy5])
      this.map.on('click', function (evt) {
        vm.x = evt.latlng.lng
        vm.y = evt.latlng.lat
        vm.latlng = evt.latlng
        // vm.Sqlquery()
      })
    },
    changeData (name) {
      if (this.map.hasLayer(this.querygroup)) {
        this.querygroup.clearLayers()
      }
      if (this.map.hasLayer(this.zjtxgroupLayer)) {
        this.map.removeLayer(this.zjtxgroupLayer)
      }
      if (this.map.hasLayer(this.glqygroupLayer)) {
        this.map.removeLayer(this.glqygroupLayer)
      }

      // this.zjtxgroupLayer.clearLayers()
      // this.glqygroupLayer.clearLayers()
      switch (name) {
        case 'gaoxin':
          // if(this.map.hasLayer(this.yuanqu)) {
          //   this.map.removeLayer(this.yuanqu)
          // }

          this.gaoxin.addTo(this.querygroup).addTo(this.map)
          // this.jiedao.addTo(this.map)
          break
        case 'sishang':
          this.sishang.addTo(this.querygroup).addTo(this.map)
          break
        case 'zjtx':
          this.zjtxgroupLayer.addTo(this.map).addTo(this.map)
          break
        case 'glqy':
          this.glqygroupLayer.addTo(this.map).addTo(this.map)
          break

        default:
          break
      }
    },
    selectNav (val) {
      this.selectedNav = val
      switch (val) {
        case 'top':
          this.navMenuList = this.topList
          this.changeData('glqy')
          break
        case 'bottom':
          this.navMenuList = this.bottomList
          this.changeData('gaoxin')
          break
        case 'left':
          this.navMenuList = this.leftList
          this.changeData('zjtx')
          break
        case 'right':
          this.navMenuList = this.rightList
          this.changeData('sishang')
          break

        default:
          break
      }
    },
    changeQuarter (val) {
      if (val == 1) {
        this.projectNum = 23
      } else if (val == 2) {
        this.projectNum = 15
      }
    },
    handleCheckAllChange (val) {
      this.checkedCities = val ? cityOptions : []
      this.isIndeterminate = false
    },
    handleCheckedCitiesChange (value) {
      let checkedCount = value.length
      this.checkAll = checkedCount === this.cities.length
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.cities.length
    },
    legendSelect (chart, item) {
      // console.log('66444')
      var ranDomNum = Math.ceil(Math.random(100) * 100)
      if (chart == 'chartA') {
        if (item.text == 'All') {
          this.chartCTitle = {
            num: '+' + 1474.6,
            changeNum: '+' + 15.8
          }
          this.allChartLegend[
            chart
          ].optionsTitle = `+${ranDomNum}亿元\n同比+${ranDomNum}%`
        } else {
          this.chartCTitle = {
            num: '+' + item.value,
            changeNum: '+' + item.percent
          }
          this.allChartLegend[
            chart
          ].optionsTitle = `-${ranDomNum}亿元\n同比-${ranDomNum}%`
        }
      }
      if (chart == 'chartB') {
        // if (ranDomNum > 60) {
        //   this.chartCTitle = {
        //     num: '+' + ranDomNum,
        //     changeNum: '+' + ranDomNum
        //   }
        //   this.allChartLegend[
        //     chart
        //   ].optionsTitle = `+${ranDomNum}亿元\n同比+${ranDomNum}%`
        // } else {
        //   this.chartCTitle = {
        //     num: '-' + ranDomNum,
        //     changeNum: '-' + ranDomNum
        //   }
        //   this.allChartLegend[
        //     chart
        //   ].optionsTitle = `-${ranDomNum}亿元\n同比-${ranDomNum}%`
        // }
      }

      this.allChartLegend[chart].currentChartSelect = item.text
    }
  }
}
</script>
<style lang="less" scoped>
.main-center {
}
#map {
  width: 100%;
  height: 100%;
  min-height: 800px;
}
.IndustryDistributionMap {
  //   padding: 0 1.3125rem;
  //   height: 85vh;
  .container {
    padding: 0 1.3125rem;
  }
}

.top_selected {
  width: 8.125rem !important;
  height: 3.25rem !important;
  position: absolute;
  top: 2.4375rem;
  left: 6.625rem;
  z-index: 8;
}
.bottom_selected {
  width: 8.125rem !important;
  height: 3.25rem !important;
  position: absolute;
  bottom: 2.4375rem;
  left: 6.625rem;
  z-index: 8;
}
.left_selected {
  width: 3.3125rem !important;
  height: 8.125rem !important;
  position: absolute;
  bottom: 4.3125rem;
  left: 4.6875rem;
  z-index: 8;
}
.right_selected {
  width: 3.3125rem !important;
  height: 8.125rem !important;
  position: absolute;
  bottom: 4.3125rem;
  right: 4.375rem;
  z-index: 8;
}
.el-container {
  height: 100%;
}
.left_aside {
  //   width: 21.125rem /* 338/16 */ !important;
  width: 25rem /* 400/16 */ !important;
  height: 100% !important;
  margin-top: 20px;

  .left_top {
  }
  .left_bottom {
    margin-top: 0.625rem /* 10/16 */;
    margin-bottom: 0.625rem /* 10/16 */;
    .switchBtn {
      margin: 0.625rem 0.625rem 0 /* 10/16 */;
      display: flex;
      justify-content: space-between;
    }
    .tabBar {
      margin-top: 1.25rem /* 20/16 */;
      display: flex;
      line-height: 3.125rem /* 50/16 */;
      height: 3.125rem /* 50/16 */;
      background: url('../../assets/images/industryMap/left/02@2x.png');
      // padding: 0 10px;
      .itemOne {
        align-items: center;
        padding: 0 1.4375rem /* 23/16 */;
        width: 33.3%;
        text-align: center;
        cursor: pointer;

        img {
          width: 1.25rem /* 20/16 */;
          height: 1.125rem /* 18/16 */;
          margin-right: 0.375rem /* 6/16 */;
        }
        span {
          font-size: 1rem /* 16/16 */;
          font-family: Source Han Sans CN;
          font-weight: normal;

          color: #d0deee;
        }
      }
      .actived_itemOne {
        background: url('../../assets/images/industryMap/left/ditu@2x.png')
          no-repeat;
        background-size: cover;
        background-position-x: center;
        background-position-y: center;
        span {
          font-size: 1rem /* 16/16 */;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #ffffff;
        }
      }
    }
    .spera1 {
      margin-top: 20px;
      display: flex;
      width: 100%;
      height: 1.875rem /* 30/16 */;
      background: #0f3753;
      box-shadow: inset 0px 6px 18px 0px rgba(21, 154, 255, 0.9);
      // opacity: 0.7;
      padding: 0 /* 16/16 */ 0.5625rem /* 9/16 */;
      align-items: center;
      justify-content: center;
      span {
        color: #fff;
      }
    }
    .itemList {
      padding: 0 0.625rem /* 10/16 */;
      .item {
        color: #fff;
        margin: 0.625rem /* 10/16 */ 0;
        cursor: pointer;
        &:hover {
          background: #0f3753;
          color: skyblue;
        }
      }
    }
  }
  .top_content {
    // height: 562px;
    height: 100%;
    padding: 20px 0;
    // display: flex;
    // align-items: center;
    // flex-direction: column;
    position: relative;
    .left_sel1 {
      display: flex;
      padding: 0 20px;
      justify-content: space-between;
      .date-container {
        color: #fff;
      }
      .select-op-container {
        width: 12.5rem;
      }
    }
    .left_sel2 {
      padding: 0 20px;
      margin-top: 10px;
      //   display: flex;
      .left_op,
      .right_op {
        width: 100%;
      }
    }
  }
  .left_bott {
    margin-top: 20px;
    height: 296px;
  }
  .bottom_content {
    height: 274px;
  }
}
.right_aside {
  width: 27.5rem /* 440/16 */ !important;
  margin-left: 0.625rem;
  .spera1 {
    margin-top: 20px;
    display: flex;
    width: 100%;
    height: 3.125rem /* 50/16 */;
    background: #0f3753;
    box-shadow: inset 0px 6px 18px 0px rgba(21, 154, 255, 0.9);
    // opacity: 0.7;
    padding: 0 /* 16/16 */ 0.5625rem /* 9/16 */;
    align-items: center;
    justify-content: space-between;
    span {
      color: #fff;
    }
    .btn {
      background: linear-gradient(0deg, #149afe 0%, rgba(20, 154, 254, 0) 100%);
      font-weight: bold;
      color: #fff;
      padding: 10px;
      cursor: pointer;
    }
  }
  .linkBtnList {
    margin-top: 1.25rem /* 20/16 */;
    display: flex;
    justify-content: space-around;
  }
  .itemList {
    margin-top: 13px;
    padding-left: 10px;
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        width: 50%;
        margin-top: 19px;
        .title {
          position: relative;
          img {
            height: 10px;
          }
          span {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #d0deee;
            position: absolute;
            top: 2px;
            left: 3px;
          }
        }
        p {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
          margin-top: 5px;
          padding-left: 2px;
        }
      }
    }
  }
  .right_top {
    .data-num-container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .data-num-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0.75rem;
        width: 13.4375rem;
        height: 3.25rem;
        background: #0f3753;
        border: 1px solid #159aff;
        border-width: 1px 2px 1px 2px;
        border-left-color: #3be8ff;
        border-right-color: #3be8ff;
        box-shadow: 0px 6px 18px 0px rgba(21, 154, 255, 0.9) inset;
        i {
          font-style: normal;
          font-size: 1.25rem;
          font-weight: 400;
          color: #3be8ff;
          margin-right: 0.625rem;
        }
        .data-lebel {
          font-size: 1rem;
          font-weight: bold;
          color: #ffffff;
        }
        .data-num {
          font-size: 0.875rem;
          font-weight: 400;
          color: #d0deee;
        }
      }
    }
    .select-container {
      margin-top: 0.625rem;
      margin-bottom: 1.25rem;
      width: 100%;
      height: 3.125rem;
      background: #0f3753;
      border: 1px solid #159aff;
      box-shadow: 0px 6px 18px 0px rgba(21, 154, 255, 0.9) inset;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left-container {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 1.25rem;
        .date-container {
          font-size: 0.875rem;
          font-weight: 400;
          color: #ffffff;
        }
        .el-select {
          width: 7.5rem;
          height: 1.75rem;
        }
      }
      .right-container {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 1.25rem;
        .label {
          font-size: 1rem;
          font-weight: bold;
          color: #ffffff;
        }
        span {
          font-size: 0.875rem;
          font-weight: 400;
          color: #d0deee;
          i {
            font-style: normal;
            font-size: 1.25rem;
            font-weight: 400;
            color: #3be8ff;
            margin-right: 0.625rem;
          }
        }
      }
    }

    .chart-one {
      height: 20rem;
      display: flex;
      flex-direction: column;
      margin-top: 1.25rem;
      padding: 0 1.25rem;
      .chart-one-top {
        height: 3.125rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .top-left {
          background: rgba(86, 130, 163, 0.5);
          // border: 1px solid #159aff;
          // box-shadow: 0px 0px 16px 0px #159aff inset;
          height: 100%;
          width: 17.75rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 1.25rem;
          border-left: 2px solid #3be8ff;
          border-right: 2px solid #3be8ff;
          .img-icon-container {
            display: flex;
            align-items: center;
            font-size: 0.875rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #ffffff;
            img {
              height: 3.25rem;
              width: 3.25rem;
            }
          }
          .text-container {
            font-size: 0.875rem;
            // font-family: Source Han Sans CN;
            font-weight: 400;
            color: #d0deee;
            span {
              font-size: 1.25rem;
              // font-family: PangMenZhengDao;
              font-weight: 400;
              color: #3be8ff;
            }
          }
        }
        .top-right {
          width: 6.5625rem;
          margin-left: 0.625rem;
          display: flex;
          flex-direction: column;
          .top-container {
            position: relative;
            display: flex;
            align-items: center;
            span {
              font-size: 0.8125rem;
              // font-family: Microsoft YaHei;
              font-weight: 400;
              color: #d0deee;
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              z-index: 1;
            }
            i {
              width: 100%;
              height: 0.375rem;
              background: #5682a3;
              opacity: 0.5;
            }
          }
          .bottom-container {
            height: 1.75rem;
            background: rgba(86, 130, 163, 0.5);
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 0.625rem;
            span {
              font-size: 1rem;
              // font-family: PangMenZhengDao;
              font-weight: 400;
              color: #fffbf1;
            }
            img {
              width: 0.375rem;
              height: 1rem;
              margin-left: 0.625rem;
            }
          }
        }
      }
      .chart-one-bottom {
        flex-grow: 1;
        width: 100%;
        display: flex;
        align-items: center;
        .chart-main-container {
          width: 50%;
          height: 100%;
          position: relative;
        }
        .fourth-charLegend {
          // padding-bottom: 0.625rem;
          width: 50%;
          height: 100%;
          display: flex;
          align-items: center;

          justify-content: center;
          .legend-item-container {
            display: flex;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            .legend-item {
              padding-left: 1.25rem;
              display: flex;
              align-items: center;
              margin-bottom: 0.3125rem;
              cursor: pointer;
              &.special-legend-item {
                width: 100%;
              }
              .rect {
                background: #149afe;
                height: 0.625rem;
                min-width: 0.625rem;
                margin-right: 0.25rem;
                display: inline-block;
              }
              .label-text {
                color: #d0deee;
                font-size: 0.875rem;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                &.current {
                  color: #159aff;
                }
              }
            }
          }
        }
      }
      .chart-title-container {
        // background: red;
        height: 6.25rem;
        width: 6.25rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -3.125rem;
        margin-left: -3.125rem;
        background: transparent;
        // background: red;
        cursor: pointer;
        z-index: 1;
        border-radius: 50%;
        span {
          margin: 0.125rem 0;
          text-align: center;
          width: 100%;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 0.875rem;
          font-weight: 400;
          color: #d0deee;
          &.money {
            font-size: 1rem;
            font-weight: 400;
            color: #149afe;
          }
          &.num-text {
            font-size: 1rem;
            font-weight: 400;
            color: #fffbf1;
          }
          .arrow-img-container {
            display: inline-block;
            width: 0.375rem;
            height: 1rem;
            img {
              height: 100%;
              width: 100%;
            }
          }
        }
      }
    }
    .chart-two-container {
      margin-top: 1.25rem;

      .chart-two {
        height: 24.375rem;
        padding: 0 1.25rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .chart-two-top {
          margin-top: 1.25rem;
          display: flex;
          flex-direction: column;
          .top-top {
            background: rgba(86, 130, 163, 0.5);
            // border: 1px solid #159aff;
            // box-shadow: 0px 0px 16px 0px #159aff inset;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 1.25rem;
            border-left: 2px solid #3be8ff;
            border-right: 2px solid #3be8ff;
            .img-icon-container {
              display: flex;
              align-items: center;
              font-size: 0.875rem;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #ffffff;
              img {
                height: 3.25rem;
                width: 3.25rem;
              }
            }
            .text-container {
              font-size: 0.875rem;
              // font-family: Source Han Sans CN;
              font-weight: 400;
              color: #d0deee;
              span {
                font-size: 1.25rem;
                // font-family: PangMenZhengDao;
                font-weight: 400;
                color: #3be8ff;
              }
            }
          }
          .top-bottom {
            margin-top: 0.625rem;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .bottom-item {
              width: 30%;
              // margin-left: 0.625rem;
              display: flex;
              flex-direction: column;
              .top-container {
                position: relative;
                display: flex;
                align-items: center;
                span {
                  font-size: 0.8125rem;
                  // font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: #d0deee;
                  position: absolute;
                  top: 0;
                  left: 0;
                  height: 100%;
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  z-index: 1;
                }
                i {
                  width: 100%;
                  height: 0.375rem;
                  background: #5682a3;
                  opacity: 0.5;
                }
              }
              .bottom-container {
                height: 1.75rem;
                background: rgba(86, 130, 163, 0.5);
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 0.625rem;
                span {
                  font-size: 1rem;
                  // font-family: PangMenZhengDao;
                  font-weight: 400;
                  color: #fffbf1;
                }
                img {
                  width: 0.375rem;
                  height: 1rem;
                  margin-left: 0.625rem;
                }
              }
            }
          }
        }
        .chart-two-bottom {
          flex-grow: 1;
          width: 100%;
          display: flex;
          align-items: center;
          .chart-main-container {
            width: 50%;
            height: 100%;
            position: relative;
          }
          .fourth-charLegend {
            // padding-bottom: 0.625rem;
            width: 50%;
            height: 100%;
            display: flex;
            align-items: center;

            justify-content: center;
            .legend-item-container {
              display: flex;
              justify-content: center;
              flex-direction: column;
              width: 100%;
              .legend-item {
                padding-left: 1.25rem;
                display: flex;
                align-items: center;
                margin-bottom: 0.3125rem;
                cursor: pointer;
                &.special-legend-item {
                  width: 100%;
                }
                .rect {
                  background: #149afe;
                  height: 0.625rem;
                  min-width: 0.625rem;
                  margin-right: 0.25rem;
                  display: inline-block;
                }
                .label-text {
                  color: #d0deee;
                  font-size: 0.875rem;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  &.current {
                    color: #159aff;
                  }
                }
              }
            }
          }
        }
        .chart-title-container {
          // background: red;
          height: 6.25rem;
          width: 6.25rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          position: absolute;
          top: 50%;
          left: 50%;
          margin-top: -3.125rem;
          margin-left: -3.125rem;
          background: transparent;
          // background: red;
          cursor: pointer;
          z-index: 1;
          border-radius: 50%;
          span {
            margin: 0.125rem 0;
            text-align: center;
            width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 0.875rem;
            font-weight: 400;
            color: #d0deee;
            &.money {
              font-size: 1rem;
              font-weight: 400;
              color: #149afe;
            }
            &.num-text {
              font-size: 1rem;
              font-weight: 400;
              color: #fffbf1;
            }
            .arrow-img-container {
              display: inline-block;
              width: 0.375rem;
              height: 1rem;
              img {
                height: 100%;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

.content {
  padding: 20px;

  .table {
    width: 100%;
    // height: 400px;
    border: 1px solid #4c799b;
    display: flex;
    flex-wrap: wrap;
    .itemOne {
      display: flex;
      flex-wrap: nowrap;
      border-bottom: 1px solid #4c799b;
      width: 33.3%;
      min-height: 40px;
      .table_head {
        background: #159aff10;
        min-width: 135px;
        font-size: 0.875rem /* 14/16 */;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #d0deee;
        text-align: right;
        // line-height: 40px;
        padding-right: 5px;
        display: table;
        span {
          display: table-cell;
          vertical-align: middle;
        }
      }
      .table_content {
        background: transparent;
        width: 12.1875rem /* 195/16 */;
        font-size: 0.875rem /* 14/16 */;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #d0deee;
        text-align: left;
        // line-height: 40px;
        padding-left: 0.625rem; /* 10/16 */
        display: table;
        span {
          display: table-cell;
          vertical-align: middle;
        }
      }
    }
  }
  .right_bottom {
    margin-top: 30px;
    .tabs {
      position: relative;
      display: flex;
      align-items: center;
      .tabs_list {
        position: absolute;
        left: 40px;
        ul {
          display: flex;
          li {
            cursor: pointer;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #d0deee;
            margin-right: 2.5rem /* 40/16 */;
            // padding-bottom: 5px;
          }
          .actived {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 800;
            color: #ffffff;
            border-bottom: 2px solid #1598fc;
          }
        }
      }
    }
    .rb_content {
      margin-top: 30px;
      .pane {
        display: flex;
        // justify-content: space-between;
        flex-direction: row-reverse;
      }
      .rb_tabs {
        display: flex;
        .rb_left,
        .rb_center,
        .rb_right {
          cursor: pointer;
          width: 5.625rem /* 90/16 */;
          position: relative;
          span {
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
            text-align: center;
            line-height: 30px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #d0deee;
          }
        }
        .zjActviedClass {
          span {
            color: #fff;
          }
        }
      }
      .searchBar {
        display: none;
        height: 30px;
        /deep/ .input-with-select .el-input__inner {
          width: 14.5rem /* 232/16 */;
          background: #1f4662;
          border: 1px solid #159aff;
          color: #fff;
        }
        /deep/ .input-with-select .el-input-group__append {
          background: rgba(21, 154, 255, 0.3);
          border: 1px solid #159aff;
          box-shadow: 2px -3px 8px 0px rgba(21, 154, 255, 0.4);
          border: 1px solid #159aff;
          color: #fff;
          border-left: 0;
        }
      }
      .picture {
        height: 500px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .marking {
        height: 500px;
        width: 100%;
        margin-top: 100px;
        .legend {
          display: flex;
          width: 100%;
          flex-direction: row-reverse;
          padding-right: 6.25rem /* 100/16 */;
          .item {
            display: flex;
            align-items: center;
            padding-left: 10px;
            .pic {
              width: 10px;
              height: 10px;
              background: #159aff;
            }
            .pic2 {
              width: 10px;
              height: 10px;
              background: #3be8ff;
            }
            span {
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #d0deee;
              padding-left: 10px;
            }
          }
        }
        .barchart {
          width: 100%;
          height: auto;
          display: flex;
          flex-wrap: wrap;
        }
      }
    }
  }
}

.modal-line-chart {
  //   height: 25rem;
  height: 100%;
  width: 100%;
  position: relative;
  .close-btn {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 2.5rem;
    height: 2.875rem;
    z-index: 1;
    display: flex;
    align-items: center;
    img {
      cursor: pointer;
      width: 1.25rem;
      height: 1.25rem;
    }
  }
  .digtable {
    padding: 13px 20px 22px 20px;
    height: 318px;
    display: flex;
    .table_title {
      height: 40px;
      background: #0f4c7a70;
      box-shadow: inset 3px 5px 10px 0px rgba(21, 154, 255, 0.56);
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #fefefe;
      text-align: center;
      line-height: 40px;
    }
    .head_left {
      display: flex;
      width: 350px;
      padding-right: 10px;
      border-right: 1px dashed #4c799b;
      height: 100%;
      .name {
        width: 220px;
        margin-right: 10px;
        .table_cont {
          height: 70px;
          background: #1599fd50;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #36d4ec;
          text-align: center;
          line-height: 70px;
          margin-top: 20px;
        }
      }
      .possiable {
        .sm {
          width: 110px;
        }
        .table_cont2 {
          margin-top: 20px;
          height: 70px;
          background: rgba(229, 55, 59, 0.2);
          border: 1px solid #e5373b;
          font-size: 24px;
          font-family: PangMenZhengDao;
          font-weight: 400;
          color: #ffffff;
          text-align: center;
          line-height: 70px;
        }
      }
    }
    .head_right {
      display: flex;
      .cont_box {
        display: flex;
        align-items: center;
        width: 100%;
        height: 70px;
        background: #6491b330;
        color: #ffffff;
        text-align: center;
        padding: 0 10px;
      }
      .one {
        height: 100%;
        width: 320px;
        padding: 0 10px;
        border-right: 1px dashed #4c799b;
        .table_cont {
          display: flex;
          margin-top: 10px;
          align-items: center;
          .line {
            width: 59px;
            height: 2px;
            background: #3be8ff;
          }
        }
      }
    }
  }
  // width: 18.75rem;
}
</style>
<style lang="less">
.select-op-container {
  width: 7.5rem;
  height: 1.75rem;
  .el-select {
    width: 100%;
    height: 100%;
    .el-input {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      .el-input__inner {
        width: 100%;
        height: 100%;
        font-size: 0.875rem;
        font-weight: 400;
        color: #fefeff;
        background: #103552;
        border: 1px solid #159aff;
      }
      .el-input__suffix {
        display: flex;
        align-items: center;
      }
    }
  }
}
.el-select-dropdown {
  .el-scrollbar__wrap {
    overflow-x: auto;
  }
}
</style>
<style lang="less">
.IndustryDistributionMap {
  .el-dialog__header {
    display: none !important;
  }
  .el-dialog {
    background: rgba(15, 55, 83, 0.9);
  }
  .el-dialog__body {
    padding: 0;
  }
}
.el-table .dark-row {
  background: #0b2c44 !important;
}

.el-table .light-row {
  background: #0f3e60 !important;
}
.chart-main-container {
  position: relative;
  display: flex;
  align-content: center;
  justify-content: center;
}
.chart-title-container {
  // background: red;
  height: 6.25rem;
  width: 6.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  font-weight: 700;
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -3.125rem;
  margin-left: -3.125rem;
  background: transparent;
  // background: red;
  cursor: pointer;
  z-index: 1;
  border-radius: 50%;
  span {
    margin: 0.125rem 0;
    text-align: center;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
