<template>
  <div class="industrialChain">
    <el-container>
      <chart-Common title="智能网联汽车—电解液">
        <div class="content">
           <h3 style="text-align:center;color:#fff">电解液产业链</h3>
          <div class="topBar">
            <div class="btnList">
              <div
                class="btn"
                @click="$router.push('/main/IndustrialChain')"
              >
                <img
                  src="../../assets/images/IndustrialChain/anniu@2x.png"
                  alt=""
                />
                <span>返回</span>
              </div>
            </div>
            <div class="tabs">
                <div
                  :class="houseType == 1 ? 'left zjActviedClass' : 'left'"
                  @click="selectHouseType(1)"
                >
                  <img
                    :src="
                      houseType == 1
                        ? require('../../assets/images/industryHousing/typeLeft_actived.png')
                        : require('../../assets/images/industryHousing/typeLeft.png')
                    "
                    alt=""
                  />
                  <span>产业链</span>
                </div>
                <div
                  :class="houseType == 2 ? 'right zjActviedClass' : 'right'"
                  @click="selectHouseType(2)"
                >
                  <img
                    :src="
                      houseType == 2
                        ? require('../../assets/images/industryHousing/typeRight_actived.png')
                        : require('../../assets/images/industryHousing/typeRight.png')
                    "
                    alt=""
                  />
                  <span>关联企业</span>
                </div>
              </div>
            <div class="btnList">
              <div
                class="btn"
                @click="$router.push('/main/newChain/chainEnterprise_map?key=djy')"
              >
                <img
                  src="../../assets/images/IndustrialChain/anniu@2x.png"
                  alt=""
                />
                <span>企业分布地图</span>
              </div>
            </div>
          </div>

          <el-container>
            <el-main class="left_aside">
              <div class="top">
                <!-- <img
                    src="../../assets/images/IndustrialChain/d-lan@2x.png"
                    alt=""
                  /> -->
                <!-- <div
                    :class="`ballContent pos${index + 1}`"
                    v-for="(item, index) in topBallList"
                    :key="index"
                  >
                    <div class="word">
                      {{ item.name }}
                    </div>
                  </div> -->
                <!-- <tags
                  :dataList="topBallList"
                  tagsId="tag1"
                  @getPopItem="getPopItem"
                /> -->
                <!-- <div class="fresh">
                  <i class="el-icon-refresh"></i>
                  <span>刷新</span>
                </div> -->
                <tags2
                  tagsId="tag1"
                  @getPopItem="getPopItem"
                />
              </div>
              <div class="center">
                <!-- <img
                    src="../../assets/images/IndustrialChain/d-lv@2x.png"
                    alt=""
                  />
                  <div
                    :class="`ballContent pos${index + 1}`"
                    v-for="(item, index) in centerBallList"
                    :key="index"
                  >
                    <div class="word">
                      {{ item.name }}
                    </div>
                  </div> -->
                <tags
                  :dataList="centerBallList"
                  tagsId="tag2"
                  @getPopItem="getPopItem"
                />
                <!-- <div class="fresh">
                  <i class="el-icon-refresh"></i>
                  <span>刷新</span>
                </div> -->
              </div>
              <div class="bottom">
                <!-- <img
                    src="../../assets/images/IndustrialChain/d-huang@2x.png"
                    alt=""
                  />
                  <div
                    :class="`ballContent pos${index + 1}`"
                    v-for="(item, index) in bottomBallList"
                    :key="index"
                  >
                    <div class="word">
                      {{ item.name }}
                    </div>
                  </div> -->
                <tags
                  :dataList="bottomBallList"
                  tagsId="tag3"
                  @getPopItem="getPopItem"
                />
                <!-- <div class="fresh">
                  <i class="el-icon-refresh"></i>
                  <span>刷新</span>
                </div> -->
              </div>
            </el-main>
            <!-- <el-main class="main">
  
              </el-main> -->
          </el-container>
        </div>
      </chart-Common>
    </el-container>
  </div>
</template>
<script>
import chartCommon from '@/components/chartCommon/index'
import { randomInt } from 'crypto'
import tags from '@/components/tags'
import tags2 from '@/components/tags2'
export default {
  components: {
    chartCommon,
    tags,
    tags2
  },
  data () {
    return {
      menuList: [
        '智能网联汽车',
        '半导体与集成电路',
        '智能终端',
        '现代时尚',
        '激光与增材制造',
        '智能网联汽车',
        '新材料',
        '高端医疗器械',
        '生物医药',
        '大健康',
        '脑科学与类脑智能',
        '细胞与基因'
      ],
      industryType: '0',
      selectedType: 2,
      houseType: 1,
      chainData: require('../../assets/data/chain.json'),
      topBallList: [
        {
          name: '碳酸乙烯醋',
          type: this.randomRange(1, 7)
        },
        {
          name: '碳酸二甲醋',
          type: this.randomRange(1, 7)
        },
        {
          name: '碳酸二乙醋等',
          type: this.randomRange(1, 7)
        },
        {
          name: '六氟磷酸理',
          type: this.randomRange(1, 7)
        },
        {
          name: '成膜添加剂',
          type: this.randomRange(1, 7)
        },
        {
          name: '过充保护添加剂',
          type: this.randomRange(1, 7)
        },
        {
          name: '高/低温添加剂等',
          type: this.randomRange(1, 7)
        },
      ],
      centerBallList: [
        {
          name: '液体电解液',
          type: this.randomRange(1, 7)
        },
        {
          name: '固体电解液',
          type: this.randomRange(1, 7)
        },
        {
          name: '熔盐电解液等',
          type: this.randomRange(1, 7)
        }
      ],
      bottomBallList: [
        {
          name: '动力电池',
          type: this.randomRange(1, 7)
        },
        {
          name: '消费电池',
          type: this.randomRange(1, 7)
        },
        {
          name: '储能电池',
          type: this.randomRange(1, 7)
        },
        {
          name: '电容器',
          type: this.randomRange(1, 7)
        },
      ],
      tableData1: [],
      tableData2: [],
      tableData3: [],
      tableData: [
        {
          id: '001',
          enterpriseName: '深圳众新产业数字科技有限公司',
          creditId: '58447755453348877',
          name: '黄超',
          phone: '0755-84456523',
          website: 'www.zhongxincy.com',
          isGs: '是',
          tax: '8.3',
          product: '9500'
        },
        {
          id: '001',
          enterpriseName: '深圳众新产业数字科技有限公司',
          creditId: '58447755453348877',
          name: '黄超',
          phone: '0755-84456523',
          website: 'www.zhongxincy.com',
          isGs: '是',
          tax: '8.3',
          product: '9500'
        },
        {
          id: '001',
          enterpriseName: '深圳众新产业数字科技有限公司',
          creditId: '58447755453348877',
          name: '黄超',
          phone: '0755-84456523',
          website: 'www.zhongxincy.com',
          isGs: '是',
          tax: '8.3',
          product: '9500'
        },
        {
          id: '001',
          enterpriseName: '深圳众新产业数字科技有限公司',
          creditId: '58447755453348877',
          name: '黄超',
          phone: '0755-84456523',
          website: 'www.zhongxincy.com',
          isGs: '是',
          tax: '8.3',
          product: '9500'
        }
      ]
    }
  },
  created () {
    this.selectItem(0)
    this.getData()
  },
  methods: {
    getPopItem (e) {
      console.log('e :>> ', e)
    },
    selectHouseType (type) {
      this.houseType = type
      if(type==2) {
        this.$router.push('/main/newChain/chain_relati_Enterprise')
      }else if(type==1) {
        this.$router.push('/main/newChain/chainDetail')
      }
    },
    randomRange (min, max) {
      // min最小值，max最大值
      return Math.floor(Math.random() * (max - min)) + min
    },
    getData () {
      this.tableData1 = this.chainData.data1
      this.tableData2 = this.chainData.data2
      this.tableData3 = this.chainData.data3
    },
    tableHeadStyle ({ columnIndex }) {
      if (columnIndex == 0 || columnIndex == 1) {
        return `
                  background: #0e466f;
                  font-size: 14px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #FFFFFF;
                  line-height: 14px;`
      } else {
        return `
                  background: rgba(21, 154, 255, 0.3);
                  box-shadow: inset 0px 12px 10px -9px rgb(21 154 255 / 60%)
                  font-size: 14px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #FFFFFF;
                  line-height: 14px;`
      }
    },
    tableCellStyle ({ columnIndex }) {
      if (columnIndex == 1) {
        return `font-size: 14px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #FFFFFF;
                  box-shadow: 6px 0px 6px -3px rgb(21 154 255 / 50%);
                  `
      } else if (columnIndex == 2) {
        return `
                  font-size: 14px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #FFFFFF;
                  line-height: 14px;`
      } else {
        return `font-size: 14px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #D0DEEE;
                  line-height: 14px;`
      }
    },
    tableRowClassName ({ rowIndex }) {
      if (rowIndex % 2 == 0) {
        return 'dark-row'
      } else {
        return 'light-row'
      }
    },
    selectItem (type) {
      this.selectedType = type
      switch (type) {
        case 0:
          this.itemList = this.landList
          break
        case 1:
          this.itemList = this.housingList
          break

        default:
          break
      }
    }
  }
}
</script>
<style>
.el-table .dark-row {
  background: #0b2c44 !important;
}

.el-table .light-row {
  background: #0f3e60 !important;
}
</style>
<style lang="less" scoped>
.industrialChain {
  padding: 20px;
  .content {
    padding: 20px;
  }
  .topBar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .tabBar {
      width: 100%;
      display: flex;
      // height: 40px;
      line-height: 40px;
      background: url('../../assets/images/industryMap/left/02@2x.png') 100%;
      flex-wrap: wrap;
      // padding: 0 10px;
      .itemOne {
        align-items: center;
        padding: 0 23px;
        min-width: 14%;
        text-align: center;
        cursor: pointer;

        img {
          width: 20px;
          height: 18px;
          margin-right: 6px;
        }
        span {
          font-size: 1rem /* 16/16 */;
          font-family: Source Han Sans CN;
          font-weight: normal;

          color: #d0deee;
        }
      }
      .actived_itemOne {
        background: url('../../assets/images/industryMap/left/ditu@2x.png')
          no-repeat;
        background-size: cover;
        background-position-x: center;
        background-position-y: center;
        span {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #ffffff;
        }
      }
    }
    .btnList {
      display: flex;
      flex-direction: row-reverse;
      margin-top: 1.875rem /* 30/16 */;
      .btn {
        position: relative;
        cursor: pointer;
        img {
          width: 6.25rem /* 100/16 */;
          height: 2rem /* 32/16 */;
        }

        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #d0deee;
        text-align: center;
        span {
          text-align: center;
          width: 6.25rem /* 100/16 */;
          height: 2rem /* 32/16 */;
          line-height: 2rem /* 32/16 */;
          font-size: .875rem /* 14/16 */;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #d0deee;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
    .tabs {
      display: flex;
      margin-top: 1.875rem /* 30/16 */;
      .left,
      .right {
        cursor: pointer;
        width: 5.625rem /* 90/16 */;
        position: relative;
        img {
            width: 5.625rem /* 90/16 */;
            height: 1.875rem /* 30/16 */;
        }
        span {
          width: 100%;
          position: absolute;
          left: 0;
          top: 0;
          text-align: center;
          line-height: 1.875rem /* 30/16 */;
          font-size: .875rem /* 14/16 */;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #d0deee;
        }
      }
      .zjActviedClass {
        span {
          color: #fff;
        }
      }
    }
  }
  .left_aside {
    // width: 35.8125rem /* 573/16 */ !important;
    width: 100% !important;
    display: flex;
    justify-content: space-around;
    // margin-top: 30px;
    padding: 2.5rem /* 40/16 */ 0;
    .top,
    .center,
    .bottom {
      position: relative;
      margin-top: 1.8125rem /* 29/16 */;
      img {
        width: 35.8125rem /* 573/16 */;
        height: 18.0625rem /* 289/16 */;
      }
      .ballContent {
        position: absolute;
        // top: 0;
        // left: 0;
        background: url('../../assets/images/IndustrialChain/q-lan@2x.png')
          no-repeat;
        background-size: 100% 100%;
        .word {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 5.625rem /* 90/16 */;
          height: 5.625rem /* 90/16 */;
          font-size: 0.875rem /* 14/16 */;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
          line-height: 0.875rem /* 14/16 */;
        }
      }
      .pos1 {
        top: 5rem /* 80/16 */;
        left: 3.75rem /* 60/16 */;
        .word {
          width: 4.0625rem /* 65/16 */;
          height: 4.0625rem /* 65/16 */;
        }
      }
      .pos2 {
        top: 1.875rem /* 30/16 */;
        left: 12.5rem /* 200/16 */;
        .word {
          width: 5rem /* 80/16 */;
          height: 5rem /* 80/16 */;
        }
      }
      .pos3 {
        top: 0;
        left: 6.875rem /* 110/16 */;
      }
      .pos4 {
        top: 0.625rem /* 10/16 */;
        right: 4.125rem /* 66/16 */;
        .word {
          width: 4.0625rem /* 65/16 */;
          height: 4.0625rem /* 65/16 */;
        }
      }
      .pos5 {
        top: 3.75rem /* 60/16 */;
        right: 10rem /* 160/16 */;
      }
      .pos6 {
        bottom: 2.8125rem /* 45/16 */;
        right: 12.5625rem /* 201/16 */;
        .word {
          width: 5rem /* 80/16 */;
          height: 5rem /* 80/16 */;
        }
      }
      .pos7 {
        top: 10.5rem /* 168/16 */;
        left: 9.1875rem /* 147/16 */;
        .word {
          width: 4.0625rem /* 65/16 */;
          height: 4.0625rem /* 65/16 */;
        }
      }
      .pos8 {
        left: 5rem /* 80/16 */;
        top: 10.5rem /* 168/16 */;
        .word {
          width: 4.0625rem /* 65/16 */;
          height: 4.0625rem /* 65/16 */;
        }
      }
      .pos9 {
        left: 13.75rem /* 220/16 */;
        top: 5.625rem /* 90/16 */;
        .word {
          width: 4.0625rem /* 65/16 */;
          height: 4.0625rem /* 65/16 */;
        }
      }
      .pos10 {
        top: 10.5rem /* 168/16 */;
        right: 2.5rem /* 40/16 */;
        .word {
          width: 4.0625rem /* 65/16 */;
          height: 4.0625rem /* 65/16 */;
        }
      }
      .pos11 {
        top: 10.5rem /* 168/16 */;
        left: 9.1875rem /* 147/16 */;
        .word {
          width: 4.0625rem /* 65/16 */;
          height: 4.0625rem /* 65/16 */;
        }
      }
      .pos12 {
        top: 10.5rem /* 168/16 */;
        right: 7.5rem /* 120/16 */;
        .word {
          width: 4.0625rem /* 65/16 */;
          height: 4.0625rem /* 65/16 */;
        }
      }
      .pos13 {
        top: 0.3125rem /* 5/16 */;
        right: 12.5rem /* 200/16 */;
        .word {
          width: 4.0625rem /* 65/16 */;
          height: 4.0625rem /* 65/16 */;
        }
      }
      .pos14 {
        top: 5rem /* 80/16 */;
        right: 2.5rem /* 40/16 */;
        .word {
          width: 4.0625rem /* 65/16 */;
          height: 4.0625rem /* 65/16 */;
        }
      }
      .pos15 {
        top: 1.875rem /* 30/16 */;
        right: 0.4375rem /* 7/16 */;
        .word {
          width: 4.0625rem /* 65/16 */;
          height: 4.0625rem /* 65/16 */;
        }
      }
      .pos16 {
        top: 6.1875rem /* 99/16 */;
        right: 6.5rem /* 104/16 */;
        .word {
          width: 4.0625rem /* 65/16 */;
          height: 4.0625rem /* 65/16 */;
        }
      }
      .pos17 {
        bottom: 8.125rem /* 130/16 */;
        left: 9.1875rem /* 147/16 */;
        .word {
          width: 5rem /* 80/16 */;
          height: 5rem /* 80/16 */;
        }
      }
      .pos18 {
        top: 10.5rem /* 168/16 */;
        left: 16.25rem /* 260/16 */;
        .word {
          width: 4.0625rem /* 65/16 */;
          height: 4.0625rem /* 65/16 */;
        }
      }
      .pos19 {
        top: 7.5rem /* 168/16 */;
        right: 11.25rem /* 180/16 */;
        .word {
          width: 5rem /* 80/16 */;
          height: 5rem /* 80/16 */;
        }
      }
      .pos20 {
        top: 10.5rem /* 168/16 */;
        left: 9.1875rem /* 147/16 */;
        .word {
          width: 4.0625rem /* 65/16 */;
          height: 4.0625rem /* 65/16 */;
        }
      }
      .pos21 {
        left: 1.875rem /* 30/16 */;
        top: 8.125rem /* 130/16 */;
        .word {
          width: 4.0625rem /* 65/16 */;
          height: 4.0625rem /* 65/16 */;
        }
      }
      .pos22 {
        top: 3.75rem /* 60/16 */;
        .word {
          width: 4.0625rem /* 65/16 */;
          height: 4.0625rem /* 65/16 */;
        }
      }
      .pos23 {
        top: 10.5rem /* 168/16 */;
        left: 9.1875rem /* 147/16 */;
        .word {
          width: 4.0625rem /* 65/16 */;
          height: 4.0625rem /* 65/16 */;
        }
      }
      .pos24 {
        top: 4.0625rem /* 65/16 */;
        left: 17.5rem /* 280/16 */;
        .word {
          width: 4.0625rem /* 65/16 */;
          height: 4.0625rem /* 65/16 */;
        }
      }
      .pos25 {
        top: 1.25rem /* 20/16 */;
        left: 1.25rem /* 20/16 */;
        .word {
          width: 4.0625rem /* 65/16 */;
          height: 4.0625rem /* 65/16 */;
        }
      }
      .fresh {
        position: absolute;
        right: 0;
        top: 0;
        .el-icon-refresh {
          font-size: 16px;
          color: #ffffff;
        }
        span {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
    .center {
      position: relative;
      margin-top: 1.8125rem /* 29/16 */;
      img {
        width: 35.8125rem /* 573/16 */;
        height: 18.0625rem /* 289/16 */;
      }
      .ballContent {
        position: absolute;
        // top: 0;
        // left: 0;
        background: url('../../assets/images/IndustrialChain/q-lv@2x.png')
          no-repeat;
        background-size: 100% 100%;
        .word {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 3.75rem /* 60/16 */;
          height: 3.75rem /* 60/16 */;
          font-size: 0.875rem /* 14/16 */;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
          line-height: 0.875rem /* 14/16 */;
        }
      }
      .fresh {
        position: absolute;
        right: 0;
        top: 0;
        .el-icon-refresh {
          font-size: 16px;
          color: #ffffff;
        }
        span {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
    .bottom {
      position: relative;
      margin-top: 1.8125rem /* 29/16 */;
      img {
        width: 35.8125rem /* 573/16 */;
        height: 18.0625rem /* 289/16 */;
      }
      .ballContent {
        position: absolute;
        // top: 0;
        // left: 0;
        background: url('../../assets/images/IndustrialChain/q-huang@2x.png')
          no-repeat;
        background-size: 100% 100%;
        .word {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 5.625rem /* 90/16 */;
          height: 5.625rem /* 90/16 */;
          font-size: 0.875rem /* 14/16 */;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
          line-height: 0.875rem /* 14/16 */;
        }
      }
      .fresh {
        position: absolute;
        right: 0;
        top: 0;
        .el-icon-refresh {
          font-size: 16px;
          color: #ffffff;
        }
        span {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
  }
  .main {
  }
}
</style>
