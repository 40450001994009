<template>
  <div class="Charts">
    <div class="main-chart-container">
      <div class="fir-chart">
        <chart-common :title="'3+4企业数量统计'">
          <barChart
            :showLegend="false"
            :echartId="'fir-chart'"
            @showModal="initModal"
          ></barChart>
        </chart-common>
      </div>
      <div class="center-chart-container">
        <div class="sec-chart">
          <chart-common :title="'上市企业数量/产值统计'">
            <div class="chart-legend">
              <div class="chart-main-container">
                <pieBChart
                  :showLegend="false"
                  :echartId="'sec-chart'"
                  :optionsTitle="allChartLegend.chartA.optionsTitle"
                  @showModal="initModal"
                  :showType="allChartLegend.chartA.currentChartSelect"
                ></pieBChart>
              </div>
              <div class="sec-charLegend">
                <div class="legend-item-container">
                  <div class="legend-item special-legend-item">
                    <!-- <i class="rect" :style="{ background: item.color }"></i> -->
                    <span
                      @click="legendSelect('chartA', {text:'All'})"
                      class="label-text"
                      :class="{
                        current:
                          allChartLegend.chartA.currentChartSelect == 'All',
                      }"
                      >全部</span
                    >
                  </div>
                  <div
                    class="legend-item"
                    v-for="(item, index) in pieChartB"
                    :key="index"
                    @click="legendSelect('chartA', item)"
                  >
                    <i class="rect" :style="{ background: item.color }"></i>
                    <span
                      class="label-text"
                      :class="{
                        current:
                          allChartLegend.chartA.currentChartSelect == item.text,
                      }"
                      >{{ item.text }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </chart-common>
        </div>
        <div class="sec-chart">
          <chart-common :title="'拟上市企业数量/产值统计'">
            <div class="chart-legend">
              <div class="chart-main-container">
                <pieBChart
                  :showLegend="false"
                  :echartId="'third-chart'"
                  @showModal="initModal"
                   :optionsTitle="allChartLegend.chartB.optionsTitle"
                  :showType="allChartLegend.chartB.currentChartSelect"
                ></pieBChart>
              </div>
              <div class="sec-charLegend">
                <div class="legend-item-container">
                  <div class="legend-item special-legend-item">
                    <!-- <i class="rect" :style="{ background: item.color }"></i> -->
                    <span
                      @click="legendSelect('chartB', {text:'All'})"
                      class="label-text"
                      :class="{
                        current:
                          allChartLegend.chartB.currentChartSelect == 'All',
                      }"
                      >全部</span
                    >
                  </div>
                  <div
                    class="legend-item"
                    v-for="(item, index) in pieChartB"
                    :key="index"
                    @click="legendSelect('chartB', item)"
                  >
                    <i class="rect" :style="{ background: item.color }"></i>
                    <span
                      class="label-text"
                      :class="{
                        current:
                          allChartLegend.chartB.currentChartSelect == item.text,
                      }"
                      >{{ item.text }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </chart-common>
        </div>
      </div>
      <div class="third-chart">
        <chart-common :title="'本季度规上企业统计'">
          <div class="main-special-chart">
            <div class="top-count-container">
              <div class="left-chart-container">
                <div class="img-icon-container">
                  <img src="@/assets/images/charts/tongji.png" alt="" />
                  <span>总统计</span>
                </div>
                <div class="text-container">
                  <span>400</span>
                  家
                </div>
              </div>
              <div class="right-chart-container">
                <div class="left-text-container">
                  <div class="top-container">
                    <span>迁入（家）</span>
                    <i></i>
                  </div>
                  <div class="bottom-container">
                    <span>+2</span>
                    <img
                      class="arrow"
                      src="@/assets/images/charts/redarrow.png"
                      alt=""
                    />
                  </div>
                </div>
                <div class="right-text-container">
                  <div class="top-container">
                    <span>迁入（家）</span>
                    <i></i>
                  </div>
                  <div class="bottom-container">
                    <span>+2</span>
                    <img
                      class="arrow"
                      src="@/assets/images/charts/greenarrow.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="third-chart-container">
              <div class="chart-main-container">
                <!-- <pieChart
                  :echartId="'fourth-chart'"
                  :legendPosition="'right'"
                  :showLegend="false"
                  @showModal="initModal"
                   :optionsTitle="allChartLegend.chartC.optionsTitle"
                ></pieChart> -->
                <!-- :centerPosition="['30%', '50%']" -->
                 <pieBChart
                  :showLegend="false"
                  :echartId="'fourth-chart'"
                  @showModal="initModal"
                   :optionsTitle="allChartLegend.chartC.optionsTitle"
                  :showType="allChartLegend.chartC.currentChartSelect"
                  :dataList="[{name:'0.2亿-1亿',value:'200'},{name:'1-4亿',value:'312'},{name:'4-10亿',value:'502'},{name:'10-100亿',value:'102'},{name:'100-1000亿',value:'602'},{name:'1000亿以上',value:'22'}]"
                ></pieBChart>
              </div>
              <div class="fourth-charLegend">
                <div class="legend-item-container">
                  <div class="legend-item special-legend-item">
                    <!-- <i class="rect" :style="{ background: item.color }"></i> -->
                    <span
                      @click="legendSelect('chartC', {text:'All'})"
                      class="label-text"
                      :class="{
                        current:
                          allChartLegend.chartC.currentChartSelect == 'All',
                      }"
                      >全部</span
                    >
                  </div>
                  <div
                    class="legend-item"
                    v-for="(item, index) in pieChartC"
                    :key="index"
                    @click="legendSelect('chartC', item)"
                  >
                    <i class="rect" :style="{ background: item.color }"></i>
                    <span
                      class="label-text"
                      :class="{
                        current:
                          allChartLegend.chartC.currentChartSelect == item.text,
                      }"
                      >{{ item.text }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </chart-common>
      </div>
      <div class="fir-chart margin-chart">
        <!-- :showChangeBtn="true" -->
        <chart-common
          :title="'专精特新企业数量统计'"
          @showChartType="changeChart"
        >
          <barChart
            :showLegend="false"
            :rotateEdg="'0'"
            :echartId="'fifth-chart'"
            :dataAxis="['国家级', '省级', '市级']"
            :chartColor="['#2d4f5a', '#7a8854', '#E1D14F']"
            @showModal="initModal"
          ></barChart>
        </chart-common>
      </div>
      <div class="center-chart-container margin-chart">
        <div class="sec-chart">
          <chart-common :title="'四上企业数量统计'">
            <div class="chart-legend">
              <div class="chart-main-container">
                <pieChart
                  :showLegend="false"
                  :echartId="'six-chart'"
                  @showModal="initModal"
                  :optionsTitle="allChartLegend.chartD.optionsTitle"
                ></pieChart>
              </div>
              <div class="sec-charLegend">
                <div class="legend-item-container" style="width: 95%">
                  <div class="legend-item special-legend-item">
                    <!-- <i class="rect" :style="{ background: item.color }"></i> -->
                    <span
                      @click="legendSelect('chartD', {text:'All'})"
                      class="label-text"
                      :class="{
                        current:
                          allChartLegend.chartD.currentChartSelect == 'All',
                      }"
                      >全部</span
                    >
                  </div>
                  <div
                    class="legend-item"
                    v-for="(item, index) in pieChartD"
                    :style="{ width: index % 2 == 0 ? '42%' : '58%' }"
                    :key="index"
                    @click="legendSelect('chartD', item)"
                  >
                    <i class="rect" :style="{ background: item.color }"></i>
                    <span
                      class="label-text"
                      :class="{
                        current:
                          allChartLegend.chartD.currentChartSelect == item.text,
                      }"
                      >{{ item.text }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </chart-common>
        </div>
        <div class="sec-chart">
          <chart-common :title="'高新企业数量统计'">
            <barChart
              :rotateEdg="'0'"
              :echartId="'seven-chart'"
              :dataAxis="['2019年', '2020年', '2021年', '2022年']"
              :chartColor="['#184f77', '#1766a0', '#149AFE']"
              @showModal="initModal"
            ></barChart>
          </chart-common>
        </div>
      </div>
      <div class="third-chart margin-chart">
        <chart-common :title="'园区数量/占地面积/建筑面积统计'">
          <div class="line-special-chart-container">
            <div class="chart-top-container">
              <div
                class="top-item-container"
                v-for="(item, index) in 3"
                :key="index"
              >
                <div class="left-img-container">
                  <img
                    v-if="index == 0"
                    src="@/assets/images/charts/yuanqu.png"
                    alt=""
                  />
                  <img
                    v-if="index == 1"
                    src="@/assets/images/charts/zhandi.png"
                    alt=""
                  />
                  <img
                    v-if="index == 2"
                    src="@/assets/images/charts/jianzu.png"
                    alt=""
                  />
                </div>
                <div class="top-text">
                  <span>{{
                    index == 0 ? "园区" : index == 1 ? "占地面积" : "建筑面积"
                  }}</span>
                  <span><i>400</i> {{ index == 0 ? "个" : "万m²" }}</span>
                </div>
              </div>
            </div>
            <div class="lines-chart-container">
              <linesChart :echartId="'linesChart'"></linesChart>
            </div>
          </div>
          <!-- <pieBChart :showLegend="false" :echartId="'third-chart'"></pieBChart> -->
        </chart-common>
      </div>
      <div class="fir-chart margin-chart">
        <chart-common :title="'本季度项目开展情况'">
          <div class="line-special-chart-container">
            <div class="chart-top-container">
              <div
                class="top-item-container"
                v-for="(item, index) in 3"
                :key="index"
                @click="initModal(index)"
              >
                <div class="left-img-container">
                  <img
                    v-if="index == 0"
                    src="@/assets/images/charts/zongcanzhi.png"
                    alt=""
                  />
                  <img
                    v-if="index == 1"
                    src="@/assets/images/charts/touzi.png"
                    alt=""
                  />
                  <img
                    v-if="index == 2"
                    src="@/assets/images/charts/xiangmu.png"
                    alt=""
                  />
                </div>
                <div class="top-text">
                  <span>{{
                    index == 0 ? "预计总产值" : index == 1 ? "投资总额" : "重大项目"
                  }}</span>
                  <span><i>400</i> {{ index == 0||index == 1 ? "万元" : "个" }}</span>
                </div>
              </div>
            </div>
            <div class="chart-bottom-container">
              <div class="chart-bottom-item-container">
                <!-- <img src="@/assets/images/charts/huang.png" alt=""> -->
                <div class="bottom-chart">
                  <pieDChart :echartId="'pieD-chart'"></pieDChart>
                </div>
                <span class="chart-bottom-label-text">25个</span>
              </div>
              <div
                class="chart-bottom-item-container"
                :style="{
                  'background-image':
                    'url(' + require('../assets/images/charts/lv.png') + ')',
                }"
              >
                <!-- <img src="@/assets/images/charts/huang.png" alt=""> -->
                <div class="bottom-chart">
                  <pieDChart
                    :echartId="'pieDc-chart'"
                    :colorArr="['#3BE8FF', '#36EDB5']"
                    :uploadedDataURL="
                      require('@/assets/images/charts/lv_dot.png')
                    "
                    :nodataColor="['#389098', '#388b96']"
                    :optionsTitle="'施工验收'"
                  ></pieDChart>
                </div>
                <span class="chart-bottom-label-text">25个</span>
              </div>
              <div
                class="chart-bottom-item-container"
                :style="{
                  'background-image':
                    'url(' + require('../assets/images/charts/huang.png') + ')',
                }"
              >
                <!-- <img src="@/assets/images/charts/huang.png" alt=""> -->
                <div class="bottom-chart">
                  <pieDChart
                    :echartId="'pieDb-chart'"
                    :colorArr="['#F0DE4B', '#FF7225']"
                    :uploadedDataURL="
                      require('@/assets/images/charts/huang_dot.png')
                    "
                    :nodataColor="['#877e33', '#857c33']"
                    :optionsTitle="'启动项目'"
                  ></pieDChart>
                </div>
                <span class="chart-bottom-label-text">25个</span>
              </div>
            </div>
            <!-- <div class="lines-chart-container">
              <linesChart :echartId="'linesChart'"></linesChart>
            </div> -->
          </div>

          <!-- <pieDChart :echartId="'pieD-chart'"></pieDChart> -->
        </chart-common>
      </div>
      <div class="ten-chart margin-chart">
        <chart-common :title="'GDP'">
          <lineChart :echartId="'ten-chart'"></lineChart>
        </chart-common>
      </div>
      <div class="eleven-chart margin-chart">
        <chart-common :title="'本季度投资金额/同比增长率'">
          <div class="last-chart-container">
            <div class="top-chart">
              <pieCChart
                :showLegend="false"
                :echartId="'pieC-chart'"
                @changeLines="changeLines"
              ></pieCChart>
            </div>
            <div class="bottom-chart">
              <lineBChart :echartId="'lineb-chart'" :dataList="lastLineChartDataList" ref="lastLineChart"></lineBChart>
            </div>
          </div>
        </chart-common>
      </div>
    </div>
    <el-dialog
      :visible.sync="centerDialogVisible"
      width="40%"
      height="40%"
      center
      :close-on-click-modal="false"
      @closed="initModalLineChart"
    >
      <div class="modal-line-chart">
        <div class="close-btn">
          <img
            @click="centerDialogVisible = false"
            src="@/assets/images/charts/x.png"
            alt=""
          />
        </div>
        <chart-common :title="'低碳企业数量趋势图'">
          <lineBChart :dataList="modalLineChartDataList" :echartId="'line-chart'" ref="modalLineChart"></lineBChart>
        </chart-common>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import chartCommon from "@/components/chartCommon/index";
import barChart from "@/components/charts/bar";
import pieChart from "@/components/charts/pie";
import pieBChart from "@/components/charts/pieB";
import pieCChart from "@/components/charts/pieC";
import pieDChart from "@/components/charts/pieD";
import lineChart from "@/components/charts/line";
import lineBChart from "@/components/charts/lineB";
import linesChart from "@/components/charts/lines";
export default {
  components: {
    chartCommon,
    barChart,
    pieChart,
    pieBChart,
    pieCChart,
    pieDChart,
    lineChart,
    lineBChart,
    linesChart,
  },
  name: "Charts",
  data() {
    return {
      centerDialogVisible: false,
      pieChartB: [
        { color: "#149AFE", text: "30亿以内" },
        {
          color: "#4EC8D3",
          text: "30亿级",
        },
        {
          color: "#E1D14F",
          text: "100亿级",
        },
        {
          color: "#46CCA3",
          text: "200亿级",
        },
        {
          color: "#7963FF",
          text: "500亿级",
        },
        {
          color: "#D55AF8",
          text: "1000亿级",
        },
      ],
      pieChartC: [
        { color: "#149AFE", text: "0.2亿-1亿" },
        {
          color: "#4EC8D3",
          text: "1-4亿",
        },
        {
          color: "#E1D14F",
          text: "4-10亿",
        },
        {
          color: "#46CCA3",
          text: "10-100亿",
        },
        {
          color: "#7963FF",
          text: "100-1000亿",
        },
        {
          color: "#D55AF8",
          text: "1000亿以上",
        },
      ],
      pieChartD: [
        { color: "#149AFE", text: "规模以上工业企业" },
        {
          color: "#3BE8FF",
          text: "房地产企业和资质等级以上建筑",
        },
        {
          color: "#F0DE4B",
          text: "限额以上批零住餐企业",
        },
        {
          color: "##36EDB5",
          text: "规模以上服务企业",
        },
      ],
      allChartLegend: {
        chartA: {
          currentChartSelect: "All",
          optionsTitle: "500家\n220亿元",
        },
        chartB: {
          currentChartSelect: "All",
          optionsTitle: "300家\n120亿元",
        },
        chartC: {
          currentChartSelect: "All",
           optionsTitle: "+500亿元\n同比+12%",
        },
        chartD: {
          currentChartSelect: "All",
           optionsTitle: "23家\n本季度+3家",
        },
      },
      lastLineChartDataAxis:[''],
      lastLineChartDataList:[450, 432, 401, 454, 590, 530, 110, 242],
      modalLineChartDataList:[],
    };
  },
  mounted() {},
  methods: {
    //暂时未用到
    changeChart(chartType) {
      console.log(chartType, "chartType");
    },
    legendSelect(chart, item) {
      // console.log('66444')
        var ranDomNum = Math.ceil(Math.random(100)*100)
      if(chart=='chartA'||chart=='chartB'){
        this.allChartLegend[chart].optionsTitle = `${ranDomNum}家\n${ranDomNum}亿元`;
      }else if(chart=='chartC'){
        if(ranDomNum>60){
          this.allChartLegend[chart].optionsTitle = `+${ranDomNum}亿元\n同比+${ranDomNum}%`
        }else{
          this.allChartLegend[chart].optionsTitle = `-${ranDomNum}亿元\n同比-${ranDomNum}%`
        }
      }else{
         if(ranDomNum>60){
         this.allChartLegend[chart].optionsTitle = `${ranDomNum}家\n本季度+${ranDomNum}家`}else{
           this.allChartLegend[chart].optionsTitle = `${ranDomNum}家\n本季度-${ranDomNum}家`
         }
      }
      this.allChartLegend[chart].currentChartSelect = item.text;
    },
    initModal(lineChartSearchQuery) {
      console.log(lineChartSearchQuery);
      this.modalLineChartDataList = []
      for(var i=0;i<8;i++){
        this.modalLineChartDataList.push(Math.floor(Math.random(100)*500))
      }
      this.centerDialogVisible = true;
    },
    initModalLineChart(){
      this.$refs.modalLineChart.destroyeChart()
    },
    changeLines(linesType){
      console.log(linesType,"|linesType")
      if(linesType==0){
        this.lastLineChartDataList = [450, 432, 401, 454, 590, 530, 110, 242]
      }else if(linesType==1){
        this.lastLineChartDataList = [420, 402, 401, 504, 510, 520, 310, 542]
      }else{
        this.lastLineChartDataList = [220, 302, 501, 304, 110, 320, 410, 142]
      }
      // this.$refs.lastLineChart.getShowPie()
      
    }
  },
};
</script>
<style lang="less" scoped>
.Charts {
  height: 100%;
  padding: 1.25rem;
  .main-chart-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .fir-chart {
      width: 28.4375rem;
      height: 21rem;
      .top-item-container{
        cursor: pointer;
      }
    }
    .sec-chart {
      width: 25rem;
      height: 21rem;
      position: relative;
      .chart-legend {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 100%;

        .sec-charLegend {
          // position: absolute;
          // bottom: .625rem;
          // left: 0;
          padding-bottom: 0.625rem;
          height: 5rem;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          .legend-item-container {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            width: 80%;
            .legend-item {
              display: flex;
              align-items: center;
              width: 33%;
              cursor: pointer;
              &.special-legend-item {
                width: 100%;
              }
              .rect {
                background: #149afe;
                height: 0.625rem;
                min-width: 0.625rem;
                margin-right: 0.25rem;
                display: inline-block;
              }
              .label-text {
                color: #d0deee;
                font-size: 0.875rem;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                &.current {
                  color: #159aff;
                }
              }
            }
          }
        }
        .chart-main-container {
          flex-grow: 1;
          width: 100%;
        }
      }
    }
    .third-chart {
      width: 35.3125rem;
      height: 21rem;
      .main-special-chart {
        padding-top: 1.25rem;
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        .top-count-container {
          padding: 0 1.25rem;
          display: flex;
          height: 3.25rem;
          .left-chart-container {
            background: rgba(86, 130, 163, 0.5);
            // border: 1px solid #159aff;
            // box-shadow: 0px 0px 16px 0px #159aff inset;
            height: 100%;
            width: 18.375rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 1.25rem;
            border-left: 2px solid #3be8ff;
            border-right: 2px solid #3be8ff;
            .img-icon-container {
              display: flex;
              align-items: center;
              font-size: 0.875rem;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #ffffff;
              img {
                height: 3.25rem;
                width: 3.25rem;
              }
            }
            .text-container {
              font-size: 0.875rem;
              // font-family: Source Han Sans CN;
              font-weight: 400;
              color: #d0deee;
              span {
                font-size: 1.25rem;
                // font-family: PangMenZhengDao;
                font-weight: 400;
                color: #3be8ff;
              }
            }
          }
          .right-chart-container {
            display: flex;
            align-items: center;
            .left-text-container,
            .right-text-container {
              width: 6.5625rem;
              margin-left: 0.625rem;
              display: flex;
              flex-direction: column;
              .top-container {
                position: relative;
                display: flex;
                align-items: center;
                span {
                  font-size: 0.8125rem;
                  // font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: #d0deee;
                  position: absolute;
                  top: 0;
                  left: 0;
                  height: 100%;
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  z-index: 1;
                }
                i {
                  width: 100%;
                  height: 0.375rem;
                  background: #5682a3;
                  opacity: 0.5;
                }
              }
              .bottom-container {
                height: 1.75rem;
                background: rgba(86, 130, 163, 0.5);
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 0.625rem;
                span {
                  font-size: 1rem;
                  // font-family: PangMenZhengDao;
                  font-weight: 400;
                  color: #fffbf1;
                }
                img {
                  width: 0.375rem;
                  height: 1rem;
                  margin-left: 0.625rem;
                }
              }
            }
          }
        }
        .third-chart-container {
          flex-grow: 1;
          width: 100%;
          display: flex;
          align-items: center;
          .chart-main-container {
            width: 50%;
            height: 100%;
          }
          .fourth-charLegend {
            // padding-bottom: 0.625rem;
            width: 50%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            .legend-item-container {
              display: flex;
              align-items: center;
              justify-content: center;
              flex-wrap: wrap;
              width: 90%;
              .legend-item {
                display: flex;
                align-items: center;
                margin-bottom: 0.3125rem;
                width: 50%;
                cursor: pointer;
                &.special-legend-item {
                  width: 100%;
                }
                .rect {
                  background: #149afe;
                  height: 0.625rem;
                  min-width: 0.625rem;
                  margin-right: 0.25rem;
                  display: inline-block;
                }
                .label-text {
                  color: #d0deee;
                  font-size: 0.875rem;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  &.current {
                    color: #159aff;
                  }
                }
              }
            }
          }
        }
      }
    }
    .center-chart-container {
      width: 51.25rem;
      // height: 21rem;
      display: flex;
      justify-content: space-between;
      align-content: center;
    }
    .ten-chart {
      width: 51.25rem;
      height: 21rem;
    }
    .eleven-chart {
      width: 35.3125rem;
      height: 21rem;
    }
    .margin-chart {
      margin-top: 1.25rem;
      .line-special-chart-container {
        padding: 1.25rem 0.625rem;
        display: flex;
        flex-direction: column;
        height: 100%;
        .lines-chart-container {
          flex-grow: 1;
          margin-top: 0.625rem;
          width: 100%;
        }
        .chart-bottom-container {
          flex-grow: 1;
          margin-top: 0.625rem;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-around;
          .chart-bottom-item-container {
            position: relative;
            height: 10.75rem;
            width: 8.125rem;
            background-image: url("../assets/images/charts/lan.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            .bottom-chart {
              height: 100%;
              width: 100%;
            }
            .chart-bottom-label-text {
              position: absolute;
              width: 100%;
              text-align: center;
              bottom: 2rem;
              font-size: 1rem;
              // font-family: Source Han Sans CN;
              font-weight: bold;
              color: #d0deee;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .chart-top-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    // height: 5.75rem;
    height: 3rem;
    .top-item-container {
      display: flex;
      align-items: center;
      .left-img-container {
        width: 3.25rem;
        height: 3.25rem;
        img {
          height: 100%;
          width: 100%;
        }
      }
      .top-text {
        display: flex;
        flex-direction: column;
        font-size: 0.875rem;
        font-weight: 400;
        color: #d0deee;
        i {
          font-style: normal;
          font-size: 1rem;
          // font-family: PangMenZhengDao;
          font-weight: 400;
          color: #ffffff;
          margin-right: 0.125rem;
        }
      }
    }
  }
  .last-chart-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0.625rem;
    .top-chart {
      height: 40%;
      width: 100%;
    }
    .bottom-chart {
      height: 60%;
      width: 100%;
    }
  }
  .modal-line-chart {
    height: 25rem;
    width: 100%;
    position: relative;
    .close-btn {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 2.5rem;
      height: 2.875rem;
      z-index: 1;
      display: flex;
      align-items: center;
      img {
        cursor: pointer;
        width: 1.25rem;
        height: 1.25rem;
      }
    }
    // width: 18.75rem;
  }
}
</style>
<style lang="less">
.Charts {
  .el-dialog__header {
    display: none !important;
  }
  .el-dialog {
    background: rgba(15, 55, 83, 0.9);
  }
  .el-dialog__body {
    padding: 0;
  }
}
</style>
