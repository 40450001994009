<template>
  <div :id="echartId" :ref="echartId" class="echart-main"></div>
</template>

<script>
import { EleResize } from '@/utils/esresize.js'
import * as echarts from 'echarts'

export default {
  // props: ['dataXAis', 'dataList',"echartId"],
  props: {
    chartColor: {
      type: Array,
      default: () => ['#1b4965', '#30b2ca', '#3ae4fb']
    },
    echartId: {
      type: String,
      default: function () {
        return 'echartId'
      }
    },
    showLegend: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    dataXAis: {
      type: Array,
      default: () => [
        '2019Q2',
        '2019Q3',
        '2019Q4',
        '2020Q1',
        '2020Q2',
        '2020Q3',
        '2020Q4',
        '2021Q1'
      ]
    },
    dataList: {
      type: Array,
      default: () => [450, 432, 401, 454, 590, 530, 110, 242]
    }
  },
  data () {
    return {
      options: {
        backgroundColor: '', //背景颜色透明
        tooltip: {
          trigger: 'axis' //鼠标经过提示
        },
        legend: {
          show: this.showLegend,
          icon: 'roundRect', //icon为圆角矩形
          // x: "center",
          // y: "bottom",
          // data: ["样例1", "样例2", "样例3"],
          textStyle: {
            //图例文字的样式
            color: '#fff',
            fontSize: 16
          }
        },
        grid: {
          left: '5%',
          // top: "10%",
          right: '5%',
          bottom: '10%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: this.dataXAis,
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            color: '#D0DEEE'
          }
          // show: false, //隐藏x轴
        },
        yAxis: {
          name: '(单位：亿元)',
          type: 'value',
          nameTextStyle: {
            color: '#FFFFFF',
            fontSize: 12
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          //刻度延长线
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: '#354D5F'
            }
          },
          axisLabel: {
            color: '#6C8097'
          }
          // show: false, //隐藏y轴
        },
        dataZoom: [
          {
            type: 'inside'
          }
        ],
        color: ['#1DB0B8', '#37C6C0', '#D0E9FF', '#c7353a', '#f5b91e'],
        series: [
          {
            smooth: true,
            // name: "样例3",
            type: 'line',
            itemStyle: {
              color: '#6A5ACD',
              normal: {
                lineStyle: {
                  // 系列级个性化折线样式
                  width: 2,
                  type: 'solid',
                  color: '#3BDCFF'
                  // color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  //   {
                  //     offset: 0,
                  //     color: "#0000FF",
                  //   },
                  //   {
                  //     offset: 1,
                  //     color: "#CD5C5C",
                  //   },
                  // ]), //线条渐变色
                },
                label: {
                  show: true,
                  position: 'top',
                  fontSize: 12,
                  textStyle: {
                    color: 'white'
                  }
                }
              },
              emphasis: {
                color: '#6A5ACD',
                lineStyle: {
                  // 系列级个性化折线样式
                  width: 2,
                  type: 'dotted',
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: '#149AFE'
                    },
                    {
                      offset: 1,
                      color: '#149AFE'
                    }
                  ])
                }
              }
            }, //线条样式
            areaStyle: {
              normal: {
                //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: 'rgba(80,141,255,0.39)'
                  },
                  {
                    offset: 0.34,
                    color: 'rgba(56,155,255,0.25)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(38,197,254,0.00)'
                  }
                ])
              }
            }, //区域颜色渐变
            data: [450, 432, 401, 454, 590, 530, 110, 242]
          }
        ]
      }
    }
  },
  watch: {
    dataXAis: function (val) {
      this.options.xAxis.data = val
    },
    dataList: function (val) {
      this.options.series[0].data = val
      this.getShowPie()
    }
  },
  mounted () {
    // setTimeout(() => {
    this.getShowPie()
    // }, 1000)
  },
  methods: {
    getShowPie () {
      let _this = this
      this.options.xAxis.data = this.dataXAis
      this.options.series[0].data = this.dataList
      // this.options.series[0].data = data;
      // this.options.series[0].markPoint.data = data.map((item,index) => {
      //   var obj = {
      //     // value: item,
      //     symbol: 'path://M0,10 L10,10 C5.5,10 5.5,5 5,0 C4.5,5 4.5,10 0,10 z',
      //           xAxis: index, //放需要展示图片的x轴索引
      //             yAxis: item, //y轴对应的值
      //     // symbolSize: [60, 60],
      //   };
      //   return obj;
      // });
      var myCharts = this.$echarts.init(document.getElementById(this.echartId))
      var echartsid = document.getElementById(this.echartId)
      //   var myChart = this.$echarts.init(echartsid)
      myCharts.setOption(this.options)

      let listener = function () {
        myCharts.resize()
      }
      EleResize.on(echartsid, listener)
      const zoomSize = 6
      myCharts.on('click', function (params) {
        // console.log(dataXAis[Math.max(params.dataIndex - zoomSize / 2, 0)]);
        // myCharts.dispatchAction({
        //   type: "dataZoom",
        //   startValue:
        //     _this.dataXAis[Math.max(params.dataIndex - zoomSize / 2, 0)],
        //   endValue:
        //     _this.dataXAis[
        //       Math.min(params.dataIndex + zoomSize / 2, data.length - 1)
        //     ],
        // });
      })
    }
  }
}
</script>

<style>
.echart-main {
  height: 100%;
  width: 100%;
}
</style>
