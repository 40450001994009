<template>
  <div :id="echartId" :ref="echartId" class="echart-main"></div>
</template>

<script>
import { EleResize } from '@/utils/esresize.js'
import * as echarts from 'echarts'
// let dataAxis = [
//   "IT",
//   "BT",
//   "低碳",
//   "军民融合",
//   "跨境电商",
//   "建筑地产",
//   "文化创意",
// ];
// prettier-ignore
let data = [197, 397, 686, 234, 290, 330, 310];
let yMax = 500
let dataShadow = []
for (let i = 0; i < data.length; i++) {
  dataShadow.push(yMax)
}
export default {
  // props: ['dataXAis', 'dataList',"echartId"],
  props: {
    dataList: {
      type: Array,
      default: () => [197, 397, 686, 234, 290, 330, 310]
    },
    chartColor: {
      type: Array,
      default: () => ['#1b4965', '#30b2ca', '#4EC8D3']
    },
    echartId: {
      type: String,
      default: 'echartId'
    },
    rotateEdg: {
      type: String,
      default: function () {
        return '-50'
      }
    },
    dataAxis: {
      type: Array,
      default: () => [
        'IT',
        'BT',
        '低碳',
        '军民融合',
        '跨境电商',
        '建筑地产',
        '文化创意'
      ]
    }
  },
  data () {
    return {
      options: {
        grid: {
          top: 40,
          bottom: 10,
          left: 10,
          right: 20,
          containLabel: true
        },
        colorBy: 'series',
        color: [ '#4EC8D3'], //面积线
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        title: {
          // text: "特性示例：渐变色 阴影 点击缩放",
          subtext: '(单位：家)',
          subtextStyle: {
            color: '#ffffff'
          }
        },
        xAxis: {
          data: this.dataAxis,
          axisLabel: {
            inside: false,
            color: '#fff',
            rotate: this.rotateEdg
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          }
          // z: 10,
        },
        yAxis: {
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          //刻度延长线
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: '#354D5F'
            }
          },
          axisLabel: {
            color: '#999'
          }
        },
        dataZoom: [
          {
            type: 'inside'
          }
        ],
        series: [
          {
            type: 'bar',
            showBackground: true,
            barWidth: '30%',
            // itemStyle: {
            //   color:[this.chartColor[2]],
            //   // color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            //   //   // { offset: 0, color: this.chartColor[0] },
            //   //   // { offset: 0.5, color: this.chartColor[1] },
            //   //   { offset: 1, color: this.chartColor[2] },
            //   //   // { offset: 0, color: "#1b4965" },
            //   //   // { offset: 0.5, color: "#30b2ca" },
            //   //   // { offset: 1, color: "#3ae4fb" },
            //   // ]),
            // },
            colorBy: 'data',
            // emphasis: {
            //   itemStyle: {
            //     color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            //       { offset: 0, color: '#83bff6' },
            //       { offset: 0.7, color: '#188df0' },
            //       { offset: 1, color: '#188df0' }
            //     ])
            //   }
            // },
            data: data,
            markPoint: {
              data: [
                //     {
                //       symbol: "image://data:image/gif;base64,R0lGODlhEAAQAMQAAORHHOVSKudfOulrSOp3WOyDZu6QdvCchPGolfO0o/XBs/fNwfjZ0frl3/zy7////wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAkAABAALAAAAAAQABAAAAVVICSOZGlCQAosJ6mu7fiyZeKqNKToQGDsM8hBADgUXoGAiqhSvp5QAnQKGIgUhwFUYLCVDFCrKUE1lBavAViFIDlTImbKC5Gm2hB0SlBCBMQiB0UjIQA7",
                //       xAxis: 0, //放需要展示图片的x轴索引
                //       yAxis: 0, //y轴对应的值
                //     },
              ],
              symbolSize: [40, 30],
              symbolOffset: [0, '-80%'],
              label: {
                show: false
              }
            }
          }
        ]
      }
    }
  },
  watch: {
    // dataXAis: function (val) {
    //   this.options.xAxis.data = val
    // },
    // dataList: function (val) {
    //   this.options.series[0].data = val
    //   this.getShowPie()
    // }
  },
  mounted () {
    // setTimeout(() => {
    this.getShowPie()
    // }, 1000)
  },
  methods: {
    getShowPie () {
      let _this = this
      // this.options.xAxis.data = this.dataXAis
      // this.options.series[0].data = this.dataList;
      // this.options.series[0].data = data;
      // this.options.series[0].markPoint.data = data.map((item,index) => {
      //   var obj = {
      //     // value: item,
      //     symbol: 'path://M0,10 L10,10 C5.5,10 5.5,5 5,0 C4.5,5 4.5,10 0,10 z',
      //           xAxis: index, //放需要展示图片的x轴索引
      //             yAxis: item, //y轴对应的值
      //     // symbolSize: [60, 60],
      //   };
      //   return obj;
      // });
      var myCharts = this.$echarts.init(document.getElementById(this.echartId))
      var echartsid = document.getElementById(this.echartId)
      //   var myChart = this.$echarts.init(echartsid)
      myCharts.setOption(this.options)

      let listener = function () {
        myCharts.resize()
      }
      EleResize.on(echartsid, listener)
      const zoomSize = 6
      myCharts.on('click', function (params) {
        console.log('params :>> ', params);
        _this.$emit('showModal', params)
        // console.log(dataAxis[Math.max(params.dataIndex - zoomSize / 2, 0)]);
        // myCharts.dispatchAction({
        //   type: "dataZoom",
        //   startValue: _this.dataAxis[Math.max(params.dataIndex - zoomSize / 2, 0)],
        //   endValue:
        //     _this.dataAxis[
        //       Math.min(params.dataIndex + zoomSize / 2, data.length - 1)
        //     ],
        // });
      })
    }
  }
}
</script>

<style>
.echart-main {
  height: 100%;
  width: 100%;
}
</style>
