<template>
  <div :id="echartId" :ref="echartId" class="echart-main"></div>
</template>

<script>
import { EleResize } from "@/utils/esresize.js";
import echarts from "echarts";

export default {
  // props: ['dataXAis', 'dataList',"echartId"],
  props: {
    chartColor: {
      type: Array,
      default: () => ["#1b4965", "#30b2ca", "#3ae4fb"],
    },
    echartId: {
      type: String,
      default: function () {
        return "echartId";
      },
    },
    showLegend: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    dataAxis: {
      type: Array,
      default: () => [
        "2017",
        "2018",
        "2019",
        "2020",
        "2021",
        "2022",
      ],
    },
  },
  data() {
    return {
      options: {
        backgroundColor: "", //背景颜色透明
        tooltip: {
          trigger: "axis", //鼠标经过提示
        },
        legend: {
          icon: "diamond", //icon为圆角矩形
          // x: "center",
          // y: "bottom",
          // data: ["样例1", "样例2", "样例3"],
          textStyle: {
            //图例文字的样式
            color: "#fff",
            fontSize: 12,
          },
        },
        grid: {
          left: "0%",
          // top: "25%",
          right: "0%",
          bottom: "0%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: this.dataAxis,
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            color: "#D0DEEE",
          },
          // show: false, //隐藏x轴
        },
        yAxis: {
          // name: "(单位：亿元)",
          type: "value",
          nameTextStyle: {
            color: "#FFFFFF",
            fontSize: 12,
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          //刻度延长线
          splitLine: {
            lineStyle: {
              type: "dashed",
              color: "#354D5F",
            },
          },
          axisLabel: {
            color: "#6C8097",
          },
          // show: false, //隐藏y轴
        },
        dataZoom: [
          {
            type: "inside",
          },
        ],
        color: ["#149AFE", "#3BDCFF", "#F0DE4B"],
        series: [
          {
            name: "法图工业用地产业园",
            type: "line",
            smooth: true,
            itemStyle: {
              normal: {
                lineStyle: {
                  // 系列级个性化折线样式
                  width: 2,
                  type: "solid",
                  color:'#149AFE',
                  // color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  //   {
                  //     offset: 0,
                  //     color: "green",
                  //   },
                  //   {
                  //     offset: 1,
                  //     color: "red",
                  //   },
                  // ]), //线条渐变色
                },
                 label: {
                  show: true,
                  position: "top",
                  fontSize: 12,
                  textStyle: {
                    color: "white",
                  },
                },
              },
            }, //线条样式
            data: [120, 132, 101, 134, 90, 230, 210, 155],
          },
          {
            name: "非法图工业用地产业园",
            type: "line",
            smooth: true,
            itemStyle: {
              normal: {
                lineStyle: {
                  // 系列级个性化折线样式
                  width: 2,
                  type: "solid",
                  color:'#3BDCFF',
                  // color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  //   {
                  //     offset: 0,
                  //     color: "#8470FF",
                  //   },
                  //   {
                  //     offset: 1,
                  //     color: "#008B45",
                  //   },
                  // ]), //线条渐变色
                },
                 label: {
                  show: true,
                  position: "top",
                  fontSize: 12,
                  textStyle: {
                    color: "white",
                  },
                },
              },
         
            }, //线条样式
            data: [220, 282, 201, 234, 290, 430, 410, 224],
          },
          {
            smooth: true,
            name: "工业区块线产业园",
            type: "line",
            itemStyle: {
              normal: {
                lineStyle: {
                  // 系列级个性化折线样式
                  width: 2,
                  type: "solid",
                  color: "#F0DE4B",
                  // color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  //   {
                  //     offset: 0,
                  //     color: "#0000FF",
                  //   },
                  //   {
                  //     offset: 1,
                  //     color: "#CD5C5C",
                  //   },
                  // ]), //线条渐变色
                },
                 label: {
                  show: true,
                  position: "top",
                  fontSize: 12,
                  textStyle: {
                    color: "white",
                  },
                },
              },
            }, 
            data: [450, 432, 401, 454, 590, 530, 110, 242],
          },
        ],
      },
    };
  },
  watch: {
    // dataXAis: function (val) {
    //   this.options.xAxis.data = val
    // },
    // dataList: function (val) {
    //   this.options.series[0].data = val
    //   this.getShowPie()
    // }
  },
  mounted() {
    // setTimeout(() => {
    this.getShowPie();
    // }, 1000)
  },
  methods: {
    getShowPie() {
      let _this = this;
      // this.options.xAxis.data = this.dataXAis
      // this.options.series[0].data = this.dataList;
      // this.options.series[0].data = data;
      // this.options.series[0].markPoint.data = data.map((item,index) => {
      //   var obj = {
      //     // value: item,
      //     symbol: 'path://M0,10 L10,10 C5.5,10 5.5,5 5,0 C4.5,5 4.5,10 0,10 z',
      //           xAxis: index, //放需要展示图片的x轴索引
      //             yAxis: item, //y轴对应的值
      //     // symbolSize: [60, 60],
      //   };
      //   return obj;
      // });
      var myCharts = this.$echarts.init(document.getElementById(this.echartId));
      var echartsid = document.getElementById(this.echartId);
      //   var myChart = this.$echarts.init(echartsid)
      myCharts.setOption(this.options);

      let listener = function () {
        myCharts.resize();
      };
      EleResize.on(echartsid, listener);
      const zoomSize = 6;
      myCharts.on("click", function (params) {
        // console.log(dataAxis[Math.max(params.dataIndex - zoomSize / 2, 0)]);
        // myCharts.dispatchAction({
        //   type: "dataZoom",
        //   startValue:
        //     _this.dataAxis[Math.max(params.dataIndex - zoomSize / 2, 0)],
        //   endValue:
        //     _this.dataAxis[
        //       Math.min(params.dataIndex + zoomSize / 2, data.length - 1)
        //     ],
        // });
      });
    },
  },
};
</script>

<style>
.echart-main {
  height: 100%;
  width: 100%;
}
</style>
