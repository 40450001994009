<template>
  <div :id="echartId" :ref="echartId" class="echart-main"></div>
</template>

<script>
import * as echarts from 'echarts';
import { EleResize } from "@/utils/esresize.js";
export default {
  // props: ['dataXAis', 'dataList','echartId'],
  props: {
    echartId: {
      type: String,
      default: function () {
        return "echartId";
      },
    },
    showLegend: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
    optionsTitle: {
      type: String,
      default: function () {
        return "前期项目";
      },
    },
    colorArr: {
      default: function () {
        return ["#149AFE", "#3BE8FF"];
      },
    },
    nodataColor: {
      default: function () {
        return ["#316d98", "#316a95"];
      },
    },
    uploadedDataURL: {
      // type:String,
      default: function () {
        return require("@/assets/images/charts/lan_dot.png");
      },
    },
  },
  data() {
    return {
      options: {
        legend: {
          top: "bottom",
          textStyle: {
            color: "#D0DEEE",
          },
          itemWidth: 14,
          itemHeight: 14,
          show: this.showLegend,
        },
        title: {
          // show: false,
          text: this.optionsTitle,
          top: "20%",
          left: "49%",
          textAlign: "center",
          textStyle: {
            color: "#D0DEEE",
            fontSize: "14",
            lineHeight: 20,
          },
        },
        tooltip: {
          show: false,
        },
        color: ["#01dadc", "#23cea7", "#5096e0"],
        legend: {
          orient: "vertical",
          x: 690,
          y: 120,
          data: ["危急", "严重", "一般"],
        },
        series: [
          {
            // name: "一般",
            type: "pie",
            //起始刻度的角度，默认为 90 度，即圆心的正上方。0 度为圆心的正右方。
            startAngle: 0,
            hoverAnimation: false,
            // tooltip: {},
            clockWise: true,
            radius: ["60%", "80%"],
            center: ["50%", "80%"],
            // label: {
            //   normal: {
            //     show: false,
            //     position: "center",
            //     color: "#fff",
            //     formatter: function (params) {
            //       return params.value;
            //     },
            //   },
            // },
            labelLine: {
              normal: {
                show: false,
              },
            },
            data: [
              {
                value: 300,
                itemStyle: {
                  normal: {
                    color: "rgba(80,150,224,0)",
                  },
                },
              },
              {
                value: 180,
                // itemStyle: {
                //   normal: {
                //     color: "rgba(80,150,224,1)",
                //   },
                // },
                itemStyle: {
                  normal: {
                    color: {
                      type: "linear",
                      colorStops: [
                        // !! 在此添加想要的渐变过程色 !!
                        { offset: 0, color: this.colorArr[0] },
                        { offset: 1, color: this.colorArr[1] },
                      ],
                    },

                    shadowColor: "rgba(34,192,245,0.8)",
                    shadowBlur: 10,
                   
                  },
                },
                // itemStyle: {
                //   normal: {
                //     color: "rgba(80,150,224,1)",
                //     borderWidth: 1,
                //     borderColor: new echarts.graphic.LinearGradient(
                //       0,
                //       0,
                //       0,
                //       1,
                //       [
                //         {
                //           offset: 0,
                //           color: "#459EF9",
                //         },
                //         {
                //           offset: 1,
                //           color: "#39FAD8",
                //         },
                //       ]
                //     ),
                //     // borderWidth: 5, //就是在这里加宽度
                //   },
                // },
              },
              {
                //进度条起始地方有个圆环，是放了个图片上去的，这是调整图片的
                name: "",
                value: 0,
                label: {
                  position: "inside",
                  backgroundColor: {
                    image: this.uploadedDataURL,
                  },
                  width: 16,
                  height: 16,
                  borderRadius: 20,
                  padding: 14,
                },
              },
              {
                value: 120,
                itemStyle: {
                  normal: {
                    // color: "#316A95",
                    // color: "rgba(49,106,149,0.5)",
                    // color: 'transparent',
                      color: {
                      type: "linear",
                      colorStops: [
                        // !! 在此添加想要的渐变过程色 !!
                        { offset: 0, color: this.nodataColor[0] },
                        { offset: 1, color: this.nodataColor[1] },
                      ],
                    },
                  },
                },
                
              },
            ],
          },
          // {
          //   type: "image",
          //   // style: imageStyle,
          //   clipPath: {
          //     type: "polygon",
          //     shape: {
          //       points: makePionterPoints(params, polarEndRadian),
          //     },
          //     extra: {
          //       polarEndRadian: polarEndRadian,
          //       transition: "polarEndRadian",
          //       enterFrom: { polarEndRadian: 0 },
          //     },
          //     during: function (apiDuring) {
          //       apiDuring.setShape(
          //         "points",
          //         makePionterPoints(
          //           params,
          //           apiDuring.getExtra("polarEndRadian")
          //         )
          //       );
          //     },
          //   },
          // },
        ],
      },
    };
  },
  watch: {
    // dataXAis: function (val) {
    //   this.options.xAxis.data = val
    // },
    // dataList: function (val) {
    //   this.options.series[0].data = val
    //   this.getShowPie()
    // }
  },
  mounted() {
    // setTimeout(() => {
    this.getShowPie();
    // }, 1000)
  },
  methods: {
    getShowPie() {
      // this.options.xAxis.data = this.dataXAis
      // this.options.series[0].data = this.dataList;
      var myCharts = this.$echarts.init(document.getElementById(this.echartId));
      var echartsid = document.getElementById(this.echartId);
      //   var myChart = this.$echarts.init(echartsid)
      myCharts.setOption(this.options);

      let listener = function () {
        myCharts.resize();
      };
      EleResize.on(echartsid, listener);
    },
  },
};
</script>

<style>
.echart-main {
  height: 100%;
  width: 100%;
}
</style>
