<template>
  <div class="industrialChain">
    <el-container>
      <chart-Common title="智能网联汽车—电解液">
        <div class="content">
          <h3 style="text-align: center; color: #fff">电解液产业链</h3>
          <div class="topBar">
            <div class="btnList">
              <div
                class="btn"
                @click="$router.push('/main/IndustrialChain')"
              >
                <img
                  src="../../assets/images/IndustrialChain/anniu@2x.png"
                  alt=""
                />
                <span>返回</span>
              </div>
            </div>
            <div class="tabs">
              <div
                :class="houseType == 1 ? 'left zjActviedClass' : 'left'"
                @click="selectHouseType(1)"
              >
                <img
                  :src="
                    houseType == 1
                      ? require('../../assets/images/industryHousing/typeLeft_actived.png')
                      : require('../../assets/images/industryHousing/typeLeft.png')
                  "
                  alt=""
                />
                <span>产业链</span>
              </div>
              <div
                :class="houseType == 2 ? 'right zjActviedClass' : 'right'"
                @click="selectHouseType(2)"
              >
                <img
                  :src="
                    houseType == 2
                      ? require('../../assets/images/industryHousing/typeRight_actived.png')
                      : require('../../assets/images/industryHousing/typeRight.png')
                  "
                  alt=""
                />
                <span>关联企业</span>
              </div>
            </div>
            <div class="btnList">
              <div
                class="btn"
                @click="$router.push('/main/newChain/chainEnterprise_map?key=djy')"
              >
                <img
                  src="../../assets/images/IndustrialChain/anniu@2x.png"
                  alt=""
                />
                <span>企业分布地图</span>
              </div>
            </div>
          </div>

          <el-container>
            <el-main class="left_aside">
              <div class="top">
                <img
                  src="../../assets/images/IndustrialChain/d-lan@2x.png"
                  alt=""
                />
                <div class="contentChain">
                  <div class="spera1">
                    <span> 溶剂 </span>
                  </div>
                  <div class="word_content">
                    <p>胜华新材</p>
                    <p>奥克股份</p>
                    <p>日科化学</p>
                    <p>科隆股份</p>
                  </div>
                  <div class="spera1">
                    <span> 溶质 </span>
                  </div>
                  <div class="word_content">
                    <p>永太科技</p>
                    <p>天际股份</p>
                    <p>多氟多</p>
                  </div>
                  <div class="spera1">
                    <span> 添加剂 </span>
                  </div>
                  <div class="word_content">
                    <p>华盛锂电</p>
                    <p>孚日股份</p>
                    <p>冠城大通</p>
                    <p>瑞泰新材</p>
                  </div>
                </div>
              </div>
              <div class="center">
                <img
                  src="../../assets/images/IndustrialChain/d-lv@2x.png"
                  alt=""
                />
                <div class="contentChain">
                  <div class="spera1">
                    <span> 电解液生产 </span>
                  </div>
                  <div class="word_content">
                    <p class="center_cont">
                      <span class="center_name" @click="centerDialogVisible = true" style="color:yellow">&nbsp;&nbsp;新宙邦</span
                      ><span>深圳市坪山区</span>
                    </p>
                    <p class="center_cont">
                      <span class="center_name">天赐材料</span
                      ><span>广州市</span>
                    </p>
                    <p class="center_cont">
                      <span class="center_name">杉杉股份</span
                      ><span>宁波市</span>
                    </p>
                    <p class="center_cont">
                      <span class="center_name">延安必康</span
                      ><span>延安市</span>
                    </p>
                    <p class="center_cont">
                      <span class="center_name">万盛股份</span
                      ><span>台州市</span>
                    </p>
                    <p class="center_cont">
                      <span class="center_name">江苏国泰</span
                      ><span>苏州市</span>
                    </p>
                    <p class="center_cont">
                      <span class="center_name">振华股份</span
                      ><span>黄石市</span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="bottom">
                <img
                  src="../../assets/images/IndustrialChain/d-huang@2x.png"
                  alt=""
                />

                <div class="contentChain">
                  <div class="spera1 spera3">
                    <span> 动力电池</span>
                  </div>
                  <div class="spera1 spera3">
                    <span> 消费电池 </span>
                  </div>
                  <div class="spera1 spera3">
                    <span> 储能电池 </span>
                  </div>
                  <div class="spera1 spera3">
                    <span> 电容器 </span>
                  </div>
                 
                </div>
              </div>
            </el-main>
            <!-- <el-main class="main">
    
                </el-main> -->
          </el-container>
        </div>
      </chart-Common>
    </el-container>
    <el-dialog
      :visible.sync="centerDialogVisible"
      width="1340px"
      height="40%"
      center
      :close-on-click-modal="false"
    >
      <div class="modal-line-chart">
        <div class="close-btn">
          <img
            @click="centerDialogVisible = false"
            src="@/assets/images/charts/x.png"
            alt=""
          />
        </div>
        <chart-Common title="企业基础信息">
        <div class="content">
          <div class="title_part">
            <span></span>
            <h2 class="title">深圳新宙邦科技股份有限公司</h2>
            <el-button
              class="title_btn"
                @click="$router.push('/main/newChain/chainEnterprise_map?key=qy')"
                style="background: rgba(86, 130, 163, 0.5); color: #fff"
                >查看该企业空间</el-button
              >
          </div>
          
          <div class="table">
            <div class="itemOne">
              <div class="table_head"><span> 统一社会信用代码</span></div>
              <div class="table_content">
                <span>91440300736252008C</span>
              </div>
            </div>
            <div class="itemOne">
              <div class="table_head"><span> 成立日期</span></div>
              <div class="table_content">
                <span>2002/2/19</span>
              </div>
            </div>
            <div class="itemOne">
              <div class="table_head"><span> 外观专利（件）</span></div>
              <div class="table_content"><span>--</span></div>
            </div>
            <div class="itemOne">
              <div class="table_head"><span> 企业类别</span></div>
              <div class="table_content">
                <span>{{ row.企业类型 || '--' }}</span>
              </div>
            </div>
            <div class="itemOne">
              <div class="table_head"><span> 法定代表人</span></div>
              <div class="table_content">
                <span>范家威</span>
              </div>
            </div>
            <div class="itemOne">
              <div class="table_head"><span> 实用新型专利（件）</span></div>
              <div class="table_content"><span>--</span></div>
            </div>
            <div class="itemOne">
              <div class="table_head"><span> 地址</span></div>
              <div class="table_content">
                <span>深圳市坪山区坪山街道六联社区昌业路9号新宙邦科技大厦1901</span>
              </div>
            </div>
            <div class="itemOne">
              <div class="table_head"><span> 联系电话</span></div>
              <div class="table_content">
                <span>15013569982</span>
              </div>
            </div>
            <div class="itemOne">
              <div class="table_head"><span> 企业融资（次）</span></div>
              <div class="table_content"><span>--</span></div>
            </div>
            <div class="itemOne">
              <div class="table_head"><span> 街道</span></div>
              <div class="table_content"><span>坪山街道</span></div>
            </div>
            <div class="itemOne">
              <div class="table_head"><span> 登记机关</span></div>
              <div class="table_content"><span>--</span></div>
            </div>
            <div class="itemOne">
              <div class="table_head"><span> 企业融资总金额 (万元)</span></div>
              <div class="table_content"><span>--</span></div>
            </div>
            <div class="itemOne">
              <div class="table_head"><span> 参保人数</span></div>
              <div class="table_content">
                <span>556</span>
              </div>
            </div>
            <div class="itemOne">
              <div class="table_head"><span> 产值 (亿元)</span></div>
              <div class="table_content"><span>--</span></div>
            </div>
            <div class="itemOne">
              <div class="table_head"><span> 企业对外融资（次）</span></div>
              <div class="table_content"><span>--</span></div>
            </div>
            <div class="itemOne">
              <div class="table_head"><span> 认缴资本总数</span></div>
              <div class="table_content"><span>74245.0163万元人民币</span></div>
            </div>
            <div class="itemOne">
              <div class="table_head"><span> 上一年税收金额</span></div>
              <div class="table_content"><span>--</span></div>
            </div>
            <div class="itemOne">
              <div class="table_head">
                <span> 企业对外投资总金额 (万元)</span>
              </div>
              <div class="table_content"><span>--</span></div>
            </div>
            <div class="itemOne">
              <div class="table_head"><span> 注册状态</span></div>
              <div class="table_content">
                <span>存续（在营、开业、在册）</span>
              </div>
            </div>
            <div class="itemOne">
              <div class="table_head"><span> 专利申请(件)</span></div>
              <div class="table_content"><span>--</span></div>
            </div>
            <div class="itemOne">
              <div class="table_head"><span> 行业类型</span></div>
              <div class="table_content">
                <span>{{ row.所属行业 || '--' }}</span>
              </div>
            </div>
          </div>
          <div class="right_bottom">
            <div class="tabs">
              <img src="../../assets/images/enterpriseInfo/title2.png" alt="" />
              <div class="tabs_list">
                <ul>
                  <li
                    :class="{ actived: activedTabs == 1 }"
                    @click="selectTopTabs(1)"
                  >
                    企业画像
                  </li>
                  <li
                    :class="{ actived: activedTabs == 2 }"
                    @click="selectTopTabs(2)"
                  >
                    企业对标
                  </li>
                </ul>
              </div>
            </div>
            <div class="rb_content">
              <div class="pane">
                <div class="rb_tabs">
                  <div
                    :class="zjType == 0 ? 'rb_left zjActviedClass' : 'rb_left'"
                    @click="selectType(0)"
                  >
                    <img
                      :src="
                        zjType == 0
                          ? require('../../assets/images/industryHousing/typeLeft_actived.png')
                          : require('../../assets/images/industryHousing/typeLeft.png')
                      "
                      alt=""
                    />
                    <span>2022年</span>
                  </div>
                  <div
                    :class="
                      zjType == 1 ? 'rb_center zjActviedClass' : 'rb_center'
                    "
                    @click="selectType(1)"
                  >
                    <img
                      :src="
                        zjType == 1
                          ? require('../../assets/images/industryHousing/typeCenter_actived.png')
                          : require('../../assets/images/industryHousing/typeCenter.png')
                      "
                      alt=""
                    />
                    <span>2021年</span>
                  </div>
                  <div
                    :class="
                      zjType == 2 ? 'rb_right zjActviedClass' : 'rb_right'
                    "
                    @click="selectType(2)"
                  >
                    <img
                      :src="
                        zjType == 2
                          ? require('../../assets/images/industryHousing/typeRight_actived.png')
                          : require('../../assets/images/industryHousing/typeRight.png')
                      "
                      alt=""
                    />
                    <span>2020年</span>
                  </div>
                </div>
                <div class="searchBar">
                  <el-input
                    placeholder="搜索企业名"
                    v-model="input2"
                    class="input-with-select"
                  >
                    <el-button
                      slot="append"
                      icon="el-icon-search"
                      class="appendBtn"
                    ></el-button>
                  </el-input>
                </div>
              </div>
              <div class="picture" v-if="activedTabs == 1">
                <radarChart echartId="radar1"></radarChart>
              </div>

              <div class="marking" v-if="activedTabs == 2">
                <div class="legend">
                  <div class="item">
                    <div class="pic"></div>
                    <span>对标企业</span>
                  </div>
                  <div class="item">
                    <div class="pic2"></div>
                    <span>A企业</span>
                  </div>
                </div>
                <div class="barchart">
                  <bar :echartId="k" v-for="k in 6"></bar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </chart-Common>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import chartCommon from '@/components/chartCommon/index'
import { randomInt } from 'crypto'
import tags from '@/components/tags'
import bar from '@/components/enterpriseInfo/bar'
import radarChart from '@/components/charts/radar'
export default {
  components: {
    chartCommon,
    tags,
    bar,
radarChart
  },
  data () {
    return {
        row:{},
        activedTabs: 1,
        zjType:0,
      menuList: [
        '智能网联汽车',
        '半导体与集成电路',
        '智能终端',
        '现代时尚',
        '激光与增材制造',
        '智能网联汽车',
        '新材料',
        '高端医疗器械',
        '生物医药',
        '大健康',
        '脑科学与类脑智能',
        '细胞与基因'
      ],
      centerDialogVisible:false,
      industryType: '0',
      selectedType: 2,
      houseType: 2,
      chainData: require('../../assets/data/chain.json'),
      topBallList: [
        {
          name: '碳酸乙烯醋',
          type: this.randomRange(1, 7)
        },
        {
          name: '碳酸二甲醋',
          type: this.randomRange(1, 7)
        },
        {
          name: '碳酸二乙醋等',
          type: this.randomRange(1, 7)
        },
        {
          name: '六氟磷酸理',
          type: this.randomRange(1, 7)
        },
        {
          name: '成膜添加剂',
          type: this.randomRange(1, 7)
        },
        {
          name: '过充保护添加剂',
          type: this.randomRange(1, 7)
        },
        {
          name: '高/低温添加剂等',
          type: this.randomRange(1, 7)
        }
      ],
      centerBallList: [
        {
          name: '液体电解液',
          type: this.randomRange(1, 7)
        },
        {
          name: '固体电解液',
          type: this.randomRange(1, 7)
        },
        {
          name: '熔盐电解液等',
          type: this.randomRange(1, 7)
        }
      ],
      bottomBallList: [
        {
          name: '动力电池',
          type: this.randomRange(1, 7)
        },
        {
          name: '消费电池',
          type: this.randomRange(1, 7)
        },
        {
          name: '储能电池',
          type: this.randomRange(1, 7)
        },
        {
          name: '电容器',
          type: this.randomRange(1, 7)
        }
      ],
      tableData1: [],
      tableData2: [],
      tableData3: [],
      tableData: [
        {
          id: '001',
          enterpriseName: '深圳众新产业数字科技有限公司',
          creditId: '58447755453348877',
          name: '黄超',
          phone: '0755-84456523',
          website: 'www.zhongxincy.com',
          isGs: '是',
          tax: '8.3',
          product: '9500'
        },
        {
          id: '001',
          enterpriseName: '深圳众新产业数字科技有限公司',
          creditId: '58447755453348877',
          name: '黄超',
          phone: '0755-84456523',
          website: 'www.zhongxincy.com',
          isGs: '是',
          tax: '8.3',
          product: '9500'
        },
        {
          id: '001',
          enterpriseName: '深圳众新产业数字科技有限公司',
          creditId: '58447755453348877',
          name: '黄超',
          phone: '0755-84456523',
          website: 'www.zhongxincy.com',
          isGs: '是',
          tax: '8.3',
          product: '9500'
        },
        {
          id: '001',
          enterpriseName: '深圳众新产业数字科技有限公司',
          creditId: '58447755453348877',
          name: '黄超',
          phone: '0755-84456523',
          website: 'www.zhongxincy.com',
          isGs: '是',
          tax: '8.3',
          product: '9500'
        }
      ]
    }
  },
  created () {
    this.selectItem(0)
    this.getData()
  },
  methods: {
    selectTopTabs (val) {
      this.activedTabs = val
    },
    selectType (type) {
      this.zjType = type
    },
    getPopItem (e) {
      console.log('e :>> ', e)
    },
    selectHouseType (type) {
      this.houseType = type
      if (type == 2) {
        this.$router.push('/main/newChain/chain_relati_Enterprise')
      } else if (type == 1) {
        this.$router.push('/main/newChain/chainDetail')
      }
    },
    randomRange (min, max) {
      // min最小值，max最大值
      return Math.floor(Math.random() * (max - min)) + min
    },
    getData () {
      this.tableData1 = this.chainData.data1
      this.tableData2 = this.chainData.data2
      this.tableData3 = this.chainData.data3
    },
    tableHeadStyle ({ columnIndex }) {
      if (columnIndex == 0 || columnIndex == 1) {
        return `
                    background: #0e466f;
                    font-size: 14px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 14px;`
      } else {
        return `
                    background: rgba(21, 154, 255, 0.3);
                    box-shadow: inset 0px 12px 10px -9px rgb(21 154 255 / 60%)
                    font-size: 14px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 14px;`
      }
    },
    tableCellStyle ({ columnIndex }) {
      if (columnIndex == 1) {
        return `font-size: 14px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #FFFFFF;
                    box-shadow: 6px 0px 6px -3px rgb(21 154 255 / 50%);
                    `
      } else if (columnIndex == 2) {
        return `
                    font-size: 14px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 14px;`
      } else {
        return `font-size: 14px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #D0DEEE;
                    line-height: 14px;`
      }
    },
    tableRowClassName ({ rowIndex }) {
      if (rowIndex % 2 == 0) {
        return 'dark-row'
      } else {
        return 'light-row'
      }
    },
    selectItem (type) {
      this.selectedType = type
      switch (type) {
        case 0:
          this.itemList = this.landList
          break
        case 1:
          this.itemList = this.housingList
          break

        default:
          break
      }
    }
  }
}
</script>
<style>
.el-table .dark-row {
  background: #0b2c44 !important;
}

.el-table .light-row {
  background: #0f3e60 !important;
}
</style>
<style lang="less" scoped>
.industrialChain {
  padding: 20px;
  .content {
    padding: 20px;
  }
  .topBar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .tabBar {
      width: 100%;
      display: flex;
      // height: 40px;
      line-height: 40px;
      background: url('../../assets/images/industryMap/left/02@2x.png') 100%;
      flex-wrap: wrap;
      // padding: 0 10px;
      .itemOne {
        align-items: center;
        padding: 0 23px;
        min-width: 14%;
        text-align: center;
        cursor: pointer;

        img {
          width: 20px;
          height: 18px;
          margin-right: 6px;
        }
        span {
          font-size: 1rem /* 16/16 */;
          font-family: Source Han Sans CN;
          font-weight: normal;

          color: #d0deee;
        }
      }
      .actived_itemOne {
        background: url('../../assets/images/industryMap/left/ditu@2x.png')
          no-repeat;
        background-size: cover;
        background-position-x: center;
        background-position-y: center;
        span {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #ffffff;
        }
      }
    }
    .btnList {
      display: flex;
      flex-direction: row-reverse;
      margin-top: 1.875rem /* 30/16 */;
      .btn {
        position: relative;
        cursor: pointer;
        img {
          width: 6.25rem /* 100/16 */;
          height: 2rem /* 32/16 */;
        }

        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #d0deee;
        text-align: center;
        span {
          text-align: center;
          width: 6.25rem /* 100/16 */;
          height: 2rem /* 32/16 */;
          line-height: 2rem /* 32/16 */;
          font-size: .875rem /* 14/16 */;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #d0deee;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
    .tabs {
      display: flex;
      margin-top: 1.875rem /* 30/16 */;
      .left,
      .right {
        cursor: pointer;
        width: 5.625rem /* 90/16 */;
        position: relative;
        img {
            width: 5.625rem /* 90/16 */;
            height: 1.875rem /* 30/16 */;
        }
        span {
          width: 100%;
          position: absolute;
          left: 0;
          top: 0;
          text-align: center;
          line-height: 1.875rem /* 30/16 */;
          font-size: .875rem /* 14/16 */;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #d0deee;
        }
      }
      .zjActviedClass {
        span {
          color: #fff;
        }
      }
    }
  }
  .left_aside {
    // width: 35.8125rem /* 573/16 */ !important;
    width: 100% !important;
    display: flex;
    justify-content: space-around;
    // margin-top: 30px;
    padding: 2.5rem /* 40/16 */ 0;
    .top,
    .center,
    .bottom {
      position: relative;
      margin-top: 1.8125rem /* 29/16 */;
      height: 26rem /* 400/16 */;
      img {
        width: 35.8125rem /* 573/16 */;
        // height: 21.875rem /* 350/16 */;
        height: 100%;
      }
      .contentChain {
        width: 80%;
        position: absolute;
        top: -4.375rem /* 70/16 */;
        left: 10%;
        .spera1 {
          margin-top: 1.25rem /* 20/16 */;
          display: flex;
          width: 100%;
          height: 1.875rem /* 30/16 */;
          background: #0f3753;
          box-shadow: inset 0px 6px 18px 0px rgba(21, 154, 255, 0.9);
          // opacity: 0.7;
          padding: 0 /* 16/16 */ 0.5625rem /* 9/16 */;
          align-items: center;
          justify-content: center;
          span {
            color: #fff;
            font-size: 16px;
          }
        }
        .spera3 {
            background: rgb(199,186,83);
            box-shadow: inset 0px 6px 18px 0px rgba(199,186,83,0.9);
        }
        .word_content {
          color: #fff;
          text-align: center;
          p {
            font-size: 16px;
          }
          .center_cont {
            display: flex;
            justify-content: space-around;
            margin-top: 1.25rem /* 20/16 */
          }
          .center_name {
            margin-right: 1.875rem /* 30/16 */;
            cursor: pointer;
            font-size: 16px;
          }
        }
      }
      .ballContent {
        position: absolute;
        // top: 0;
        // left: 0;
        background: url('../../assets/images/IndustrialChain/q-lan@2x.png')
          no-repeat;
        background-size: 100% 100%;
        .word {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 5.625rem /* 90/16 */;
          height: 5.625rem /* 90/16 */;
          font-size: 0.875rem /* 14/16 */;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
          line-height: 0.875rem /* 14/16 */;
        }
      }
      .pos1 {
        top: 5rem /* 80/16 */;
        left: 3.75rem /* 60/16 */;
        .word {
          width: 4.0625rem /* 65/16 */;
          height: 4.0625rem /* 65/16 */;
        }
      }
      .pos2 {
        top: 1.875rem /* 30/16 */;
        left: 12.5rem /* 200/16 */;
        .word {
          width: 5rem /* 80/16 */;
          height: 5rem /* 80/16 */;
        }
      }
      .pos3 {
        top: 0;
        left: 6.875rem /* 110/16 */;
      }
      .pos4 {
        top: 0.625rem /* 10/16 */;
        right: 4.125rem /* 66/16 */;
        .word {
          width: 4.0625rem /* 65/16 */;
          height: 4.0625rem /* 65/16 */;
        }
      }
      .pos5 {
        top: 3.75rem /* 60/16 */;
        right: 10rem /* 160/16 */;
      }
      .pos6 {
        bottom: 2.8125rem /* 45/16 */;
        right: 12.5625rem /* 201/16 */;
        .word {
          width: 5rem /* 80/16 */;
          height: 5rem /* 80/16 */;
        }
      }
      .pos7 {
        top: 10.5rem /* 168/16 */;
        left: 9.1875rem /* 147/16 */;
        .word {
          width: 4.0625rem /* 65/16 */;
          height: 4.0625rem /* 65/16 */;
        }
      }
      .pos8 {
        left: 5rem /* 80/16 */;
        top: 10.5rem /* 168/16 */;
        .word {
          width: 4.0625rem /* 65/16 */;
          height: 4.0625rem /* 65/16 */;
        }
      }
      .pos9 {
        left: 13.75rem /* 220/16 */;
        top: 5.625rem /* 90/16 */;
        .word {
          width: 4.0625rem /* 65/16 */;
          height: 4.0625rem /* 65/16 */;
        }
      }
      .pos10 {
        top: 10.5rem /* 168/16 */;
        right: 2.5rem /* 40/16 */;
        .word {
          width: 4.0625rem /* 65/16 */;
          height: 4.0625rem /* 65/16 */;
        }
      }
      .pos11 {
        top: 10.5rem /* 168/16 */;
        left: 9.1875rem /* 147/16 */;
        .word {
          width: 4.0625rem /* 65/16 */;
          height: 4.0625rem /* 65/16 */;
        }
      }
      .pos12 {
        top: 10.5rem /* 168/16 */;
        right: 7.5rem /* 120/16 */;
        .word {
          width: 4.0625rem /* 65/16 */;
          height: 4.0625rem /* 65/16 */;
        }
      }
      .pos13 {
        top: 0.3125rem /* 5/16 */;
        right: 12.5rem /* 200/16 */;
        .word {
          width: 4.0625rem /* 65/16 */;
          height: 4.0625rem /* 65/16 */;
        }
      }
      .pos14 {
        top: 5rem /* 80/16 */;
        right: 2.5rem /* 40/16 */;
        .word {
          width: 4.0625rem /* 65/16 */;
          height: 4.0625rem /* 65/16 */;
        }
      }
      .pos15 {
        top: 1.875rem /* 30/16 */;
        right: 0.4375rem /* 7/16 */;
        .word {
          width: 4.0625rem /* 65/16 */;
          height: 4.0625rem /* 65/16 */;
        }
      }
      .pos16 {
        top: 6.1875rem /* 99/16 */;
        right: 6.5rem /* 104/16 */;
        .word {
          width: 4.0625rem /* 65/16 */;
          height: 4.0625rem /* 65/16 */;
        }
      }
      .pos17 {
        bottom: 8.125rem /* 130/16 */;
        left: 9.1875rem /* 147/16 */;
        .word {
          width: 5rem /* 80/16 */;
          height: 5rem /* 80/16 */;
        }
      }
      .pos18 {
        top: 10.5rem /* 168/16 */;
        left: 16.25rem /* 260/16 */;
        .word {
          width: 4.0625rem /* 65/16 */;
          height: 4.0625rem /* 65/16 */;
        }
      }
      .pos19 {
        top: 7.5rem /* 168/16 */;
        right: 11.25rem /* 180/16 */;
        .word {
          width: 5rem /* 80/16 */;
          height: 5rem /* 80/16 */;
        }
      }
      .pos20 {
        top: 10.5rem /* 168/16 */;
        left: 9.1875rem /* 147/16 */;
        .word {
          width: 4.0625rem /* 65/16 */;
          height: 4.0625rem /* 65/16 */;
        }
      }
      .pos21 {
        left: 1.875rem /* 30/16 */;
        top: 8.125rem /* 130/16 */;
        .word {
          width: 4.0625rem /* 65/16 */;
          height: 4.0625rem /* 65/16 */;
        }
      }
      .pos22 {
        top: 3.75rem /* 60/16 */;
        .word {
          width: 4.0625rem /* 65/16 */;
          height: 4.0625rem /* 65/16 */;
        }
      }
      .pos23 {
        top: 10.5rem /* 168/16 */;
        left: 9.1875rem /* 147/16 */;
        .word {
          width: 4.0625rem /* 65/16 */;
          height: 4.0625rem /* 65/16 */;
        }
      }
      .pos24 {
        top: 4.0625rem /* 65/16 */;
        left: 17.5rem /* 280/16 */;
        .word {
          width: 4.0625rem /* 65/16 */;
          height: 4.0625rem /* 65/16 */;
        }
      }
      .pos25 {
        top: 1.25rem /* 20/16 */;
        left: 1.25rem /* 20/16 */;
        .word {
          width: 4.0625rem /* 65/16 */;
          height: 4.0625rem /* 65/16 */;
        }
      }
      .fresh {
        position: absolute;
        right: 0;
        top: 0;
        .el-icon-refresh {
          font-size: 16px;
          color: #ffffff;
        }
        span {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
    .center {
      position: relative;
      margin-top:1.8125rem /* 29/16 */;
      height: 26rem /* 400/16 */;
      img {
        width: 35.8125rem /* 573/16 */;
        // height: 289px;
        // height: 21.875rem /* 350/16 */;
      }
      .ballContent {
        position: absolute;
        // top: 0;
        // left: 0;
        background: url('../../assets/images/IndustrialChain/q-lv@2x.png')
          no-repeat;
        background-size: 100% 100%;
        .word {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 3.75rem /* 60/16 */;
          height: 3.75rem /* 60/16 */;
          font-size: 0.875rem /* 14/16 */;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
          line-height: 0.875rem /* 14/16 */;
        }
      }
      .fresh {
        position: absolute;
        right: 0;
        top: 0;
        .el-icon-refresh {
          font-size: 16px;
          color: #ffffff;
        }
        span {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
    .bottom {
      position: relative;
      margin-top: 1.8125rem /* 29/16 */;
      height: 26rem /* 400/16 */;
      img {
        width: 35.8125rem /* 573/16 */;
        // height: 289px;
        // height: 21.875rem /* 350/16 */;
      }
      .ballContent {
        position: absolute;
        // top: 0;
        // left: 0;
        background: url('../../assets/images/IndustrialChain/q-huang@2x.png')
          no-repeat;
        background-size: 100% 100%;
        .word {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 5.625rem /* 90/16 */;
          height: 5.625rem /* 90/16 */;
          font-size: 0.875rem /* 14/16 */;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
          line-height: 0.875rem /* 14/16 */;
        }
      }
      .fresh {
        position: absolute;
        right: 0;
        top: 0;
        .el-icon-refresh {
          font-size: 16px;
          color: #ffffff;
        }
        span {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
  }
  .main {
  }
}
.modal-line-chart {
//   height: 26rem;
height: 100%;
  width: 100%;
  position: relative;
  .close-btn {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 2.5rem;
    height: 2.875rem;
    z-index: 1;
    display: flex;
    align-items: center;
    img {
      cursor: pointer;
      width: 1.25rem;
      height: 1.25rem;
    }
  }
  .digtable {
    padding: 13px 20px 22px 20px;
    height: 318px;
    display: flex;
    .table_title {
      height: 40px;
      background: #0f4c7a70;
      box-shadow: inset 3px 5px 10px 0px rgba(21, 154, 255, 0.56);
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #fefefe;
      text-align: center;
      line-height: 40px;
    }
    .head_left {
      display: flex;
      width: 350px;
      padding-right: 10px;
      border-right: 1px dashed #4c799b;
      height: 100%;
      .name {
        width: 220px;
        margin-right: 10px;
        .table_cont {
          height: 70px;
          background: #1599fd50;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #36d4ec;
          text-align: center;
          line-height: 70px;
          margin-top: 20px;
        }
      }
      .possiable {
        .sm {
          width: 110px;
        }
        .table_cont2 {
          margin-top: 20px;
          height: 70px;
          background: rgba(229, 55, 59, 0.2);
          border: 1px solid #e5373b;
          font-size: 24px;
          font-family: PangMenZhengDao;
          font-weight: 400;
          color: #ffffff;
          text-align: center;
          line-height: 70px;
        }
      }
    }
    .head_right {
      display: flex;
      .cont_box {
        display: flex;
        align-items: center;
        width: 100%;
        height: 70px;
        background: #6491b330;
        color: #ffffff;
        text-align: center;
        padding: 0 10px;
      }
      .one {
        height: 100%;
        width: 320px;
        padding: 0 10px;
        border-right: 1px dashed #4c799b;
        .table_cont {
          display: flex;
          margin-top: 10px;
          align-items: center;
          .line {
            width: 59px;
            height: 2px;
            background: #3be8ff;
          }
        }
      }
    }
  }
  // width: 18.75rem;
}
.content {
      padding: 20px;
      .title_part {
        display: flex;
        justify-content: space-between;
      margin-bottom: 10px;

    .title {
      color: #fff;
    }
    .title_btn {

    }
            
  }
      .table {
        width: 100%;
        // height: 400px;
        border: 1px solid #4c799b;
        display: flex;
        flex-wrap: wrap;
        .itemOne {
          display: flex;
          flex-wrap: nowrap;
          border-bottom: 1px solid #4c799b;
          width: 33.3%;
          min-height: 40px;
          .table_head {
            background: #159aff10;
            min-width: 135px;
            font-size: 0.875rem /* 14/16 */;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #d0deee;
            text-align: right;
            // line-height: 40px;
            padding-right: 5px;
            display: table;
            span {
              display: table-cell;
              vertical-align: middle;
            }
          }
          .table_content {
            background: transparent;
            width: 12.1875rem /* 195/16 */;
            font-size: 0.875rem /* 14/16 */;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #d0deee;
            text-align: left;
            // line-height: 40px;
            padding-left: 0.625rem; /* 10/16 */
            display: table;
            span {
              display: table-cell;
              vertical-align: middle;
            }
          }
        }
      }
      .right_bottom {
        margin-top: 30px;
        .tabs {
          position: relative;
          display: flex;
          align-items: center;
          .tabs_list {
            position: absolute;
            left: 40px;
            ul {
              display: flex;
              li {
                cursor: pointer;
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #d0deee;
                margin-right: 2.5rem /* 40/16 */;
                // padding-bottom: 5px;
              }
              .actived {
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 800;
                color: #ffffff;
                border-bottom: 2px solid #1598fc;
              }
            }
          }
        }
        .rb_content {
          margin-top: 30px;
          .pane {
            display: flex;
            // justify-content: space-between;
            flex-direction: row-reverse;
          }
          .rb_tabs {
            display: flex;
            .rb_left,
            .rb_center,
            .rb_right {
              cursor: pointer;
              width: 5.625rem /* 90/16 */;
              position: relative;
              span {
                width: 100%;
                position: absolute;
                left: 0;
                top: 0;
                text-align: center;
                line-height: 30px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #d0deee;
              }
            }
            .zjActviedClass {
              span {
                color: #fff;
              }
            }
          }
          .searchBar {
            display: none;
            height: 30px;
            /deep/ .input-with-select .el-input__inner {
              width: 14.5rem /* 232/16 */;
              background: #1f4662;
              border: 1px solid #159aff;
              color: #fff;
            }
            /deep/ .input-with-select .el-input-group__append {
              background: rgba(21, 154, 255, 0.3);
              border: 1px solid #159aff;
              box-shadow: 2px -3px 8px 0px rgba(21, 154, 255, 0.4);
              border: 1px solid #159aff;
              color: #fff;
              border-left: 0;
            }
          }
          .picture {
            height: 500px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .marking {
            height: 500px;
            width: 100%;
            margin-top: 100px;
            .legend {
              display: flex;
              width: 100%;
              flex-direction: row-reverse;
              padding-right: 6.25rem /* 100/16 */;
              .item {
                display: flex;
                align-items: center;
                padding-left: 10px;
                .pic {
                  width: 10px;
                  height: 10px;
                  background: #159aff;
                }
                .pic2 {
                  width: 10px;
                  height: 10px;
                  background: #3be8ff;
                }
                span {
                  font-size: 12px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: #d0deee;
                  padding-left: 10px;
                }
              }
            }
            .barchart {
              width: 100%;
              height: auto;
              display: flex;
              flex-wrap: wrap;
            }
          }
        }
      }
    }
  
</style>
<style lang="less">
.industrialChain {
  .el-dialog__header {
    display: none !important;
  }
  .el-dialog {
    background: rgba(15, 55, 83, 0.9);
  }
  .el-dialog__body {
    padding: 0;
  }
}
.el-table .dark-row {
  background: #0b2c44 !important;
}

.el-table .light-row {
  background: #0f3e60 !important;
}
.chart-main-container {
  position: relative;
  display: flex;
  align-content: center;
  justify-content: center;
}
.chart-title-container {
  // background: red;
  height: 6.25rem;
  width: 6.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  font-weight: 700;
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -3.125rem;
  margin-left: -3.125rem;
  background: transparent;
  // background: red;
  cursor: pointer;
  z-index: 1;
  border-radius: 50%;
  span {
    margin: 0.125rem 0;
    text-align: center;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
