<template>
  <div :id="echartId" :ref="echartId" class="echart-main"></div>
</template>

<script>
import { EleResize } from '@/utils/esresize.js'

export default {
  // props: ['dataXAis', 'dataList','echartId'],
  props: {
    echartId: {
      type: String,
      default: function () {
        return 'echartId'
      }
    },
    showLegend: {
      type: Boolean,
      default: function () {
        return true
      }
    },
    optionsTitle: {
      type: String,
      default: function () {
        return '23家\n本季度+3家'
      }
    }
  },
  data () {
    return {
      currentIndex: 0,
      options: {
        legend: {
          top: 'bottom',
          textStyle: {
            color: '#D0DEEE'
          },
          itemWidth: 14,
          itemHeight: 14,
          show: this.showLegend
        },
        title: {
          show: false,
          text: this.optionsTitle,
          top: 'center',
          left: '49%',
          textAlign: 'center',
          textStyle: {
            color: 'white',
            fontSize: '14',
            lineHeight: 20
          },
          triggerEvent: true
        },
        // tooltip: {
        //   trigger: "item",
        // },
        // toolbox: {
        //   show: true,
        // },
        series: [
          {
            type: 'pie',
            radius: ['40%', '60%'],
            center: ['25%', '40%'],
            label: {
              show: false
              // position: 'center'
            },
            // color: ["#149AFE", "#316A95"],
            startAngle: 270, // 环图起始位置：正下发
            avoidLabelOverlap: false,
            hoverAnimation: false, //鼠标悬浮是否有区域弹出动画，false:无 true:有
            data: [
              {
                value: 9,
                name: '规模以上工业企业',
                color: '#149AFE',
                label: {
                  normal: {
                    show: true,
                    position: 'center',
                    color: '#FFFFFF',
                    fontSize: '18',
                    formatter: function (params) {
                      // console.log(params, "params");
                      return params.value + ' 家'
                    }
                  },
                  emphasis: {
                    show: true
                  }
                },
                itemStyle: {
                  normal: {
                    color: {
                      type: 'linear',
                      colorStops: [
                        // !! 在此添加想要的渐变过程色 !!
                                              // !! 在此添加想要的渐变过程色 !!
                        { offset: 0, color: '#3BE8FF' },
                        { offset: 1, color: '#36EDB5' }
                      ]
                    },
                    shadowColor: 'rgba(34,192,245,0.8)',
                    shadowBlur: 10
                  }
                }
              },
              {
                value: 9,
                // name: "房地产企业和资质等级以上建筑",
                label: {
                  normal: {
                    show: false,
                    fontSize: 0
                  }
                },
                itemStyle: {
                  normal: {
                    color: '#316A95'
                  },
                  emphasis: {
                    color: '#316A95'
                  }
                }
              }
            ]
          },
          {
            type: 'pie',
            radius: ['40%', '60%'],
            center: ['75%', '40%'],
            label: {
              show: false
              // position: 'center'
            },
            // color: ["#149AFE", "#316A95"],
            startAngle: 270, // 环图起始位置：正下发
            avoidLabelOverlap: false,
            hoverAnimation: false, //鼠标悬浮是否有区域弹出动画，false:无 true:有
            data: [
              {
                value: 23,
                // color: "#149AFE",
                label: {
                  normal: {
                    show: true,
                    position: 'center',
                    color: '#FFFFFF',
                    fontSize: '18',
                    formatter: function (params) {
                      // console.log(params, "params");
                      return params.value + ' 家'
                    }
                  },
                  emphasis: {
                    show: true
                  }
                },
                itemStyle: {
                  normal: {
                    color: {
                      type: 'linear',
                      colorStops: [
                          { offset: 0, color: '#169EFE' },
                        { offset: 1, color: '#3BE8FF' }

                      ]
                    },

                    shadowColor: 'rgba(34,192,245,0.8)',
                    shadowBlur: 10
                  }
                }
              },
              {
                value: 14,
                // name: "房地产企业和资质等级以上建筑",
                label: {
                  normal: {
                    show: false,
                    fontSize: 0
                  }
                },
                itemStyle: {
                  normal: {
                    color: '#388B96'
                  },
                  emphasis: {
                    color: '#388B96'
                  }
                }
              }
            ]
          }
        ]
      }
    }
  },
  watch: {
    // dataXAis: function (val) {
    //   this.options.xAxis.data = val
    // },
    // dataList: function (val) {
    //   this.options.series[0].data = val
    //   this.getShowPie()
    // }
  },
  mounted () {
    // setTimeout(() => {
    this.getShowPie()
    // }, 1000)
  },
  methods: {
    getShowPie () {
      let _this = this
      // this.options.xAxis.data = this.dataXAis
      // this.options.series[0].data = this.dataList;
      var myCharts = this.$echarts.init(document.getElementById(this.echartId))
      var echartsid = document.getElementById(this.echartId)
      myCharts.clear()
      //   var myChart = this.$echarts.init(echartsid)
      myCharts.setOption(this.options)

      let listener = function () {
        myCharts.resize()
      }
      EleResize.on(echartsid, listener)
    }
  }
}
</script>

<style>
.echart-main {
  height: 100%;
  width: 100%;
}
</style>
