<template>
  <div class="main">
    <img class="bg-img" src="@/assets/images/main/center/ditu.png" alt="" />
    <div class="top-container">
      <div class="main-bg">
        <img src="@/assets/images/main/top/daohang.png" alt="" />
      </div>
    </div>
    <div class="center-container">
      <div class="img-container">
        <img
          class="touming-img"
          src="@/assets/images/main/center/touming.png"
          alt=""
        />
      </div>
      <div class="center-main">
        <div class="left">
          <div
            class="center-main-btn left-center-top-btn"
            @click="toCenterPage('linghang')"
            @mousemove="hoverCenterImg('1')"
            @mouseleave="leaveCenterImg"
          >
            <div class="text-btn" :class="{'select-btn':currentCenterSelect == 'linghang'}">领导驾驶舱</div>
            <img
              v-if="centerUrlType == '1' || currentCenterSelect == 'linghang'"
              class="btn-img"
              src="@/assets/images/main/center/h-lingdao.png"
              alt=""
            />
            <img
              v-else
              class="btn-img"
              src="@/assets/images/main/center/lingdao.png"
              alt=""
            />
          </div>
          <div
            class="center-main-btn left-center-centerB-btn"
            @click="toCenterPage('chanye')"
            @mousemove="hoverCenterImg('2')"
            @mouseleave="leaveCenterImg"
          >
            <div class="text-btn" :class="{'select-btn':currentCenterSelect == 'chanye'}">产业链图谱</div>
            <img
              v-if="centerUrlType == '2' || currentCenterSelect == 'chanye'"
              class="btn-img"
              src="@/assets/images/main/center/h-chanyelian.png"
              alt=""
            />
            <img
              v-else
              class="btn-img"
              src="@/assets/images/main/center/chanyelian.png"
              alt=""
            />
          </div>
          <div
            class="center-main-btn left-center-centerA-btn"
            @click="toCenterPage('kongjian')"
            @mousemove="hoverCenterImg('3')"
            @mouseleave="leaveCenterImg"
          >
            <div class="text-btn" :class="{'select-btn':currentCenterSelect == 'kongjian'}">空间监测</div>
            <img
              v-if="centerUrlType == '3' || currentCenterSelect == 'kongjian'"
              class="btn-img"
              src="@/assets/images/main/center/h-jiance.png"
              alt=""
            />
            <img
              v-else
              class="btn-img"
              src="@/assets/images/main/center/jiance.png"
              alt=""
            />
          </div>
          <div
            class="center-main-btn left-center-bottom-btn"
            @click="toCenterPage('chengshi')"
            @mousemove="hoverCenterImg('4')"
            @mouseleave="leaveCenterImg"
          >
            <div class="text-btn" :class="{'select-btn':currentCenterSelect == 'chengshi'}">城市更新</div>
            <img
              v-if="centerUrlType == '4' || currentCenterSelect == 'chengshi'"
              class="btn-img"
              src="@/assets/images/main/center/h-chengshi.png"
              alt=""
            />
            <img
              v-else
              class="btn-img"
              src="@/assets/images/main/center/chengshi.png"
              alt=""
            />
          </div>
        </div>
        <div class="center-img">
          <img
            class="diqiu-img"
            src="@/assets/images/main/center/diqiu.png"
            alt=""
          />
        </div>
        <div class="right">
          <div
            class="center-main-btn right-center-top-btn"
            @click="toCenterPage('changyezl')"
            @mousemove="hoverCenterImg('5')"
            @mouseleave="leaveCenterImg"
          >
            <div class="text-btn right-text-btn" :class="{'select-btn':currentCenterSelect == 'changyezl'}">产业纵览</div>
            <img
              v-if="centerUrlType == '5' || currentCenterSelect == 'changyezl'"
              class="btn-img"
              src="@/assets/images/main/center/h-chanye.png"
              alt=""
            />
            <img
              v-else
              class="btn-img"
              src="@/assets/images/main/center/chanye.png"
              alt=""
            />
          </div>
          <div
            class="center-main-btn right-center-centerA-btn"
            @click="toCenterPage('waiqian')"
            @mousemove="hoverCenterImg('6')"
            @mouseleave="leaveCenterImg"
          >
            <div class="text-btn right-text-btn" :class="{'select-btn':currentCenterSelect == 'waiqian'}">外迁监测</div>
            <img
              v-if="centerUrlType == '6' || currentCenterSelect == 'waiqian'"
              class="btn-img"
              src="@/assets/images/main/center/h-yiqi.png"
              alt=""
            />
            <img
              v-else
              class="btn-img"
              src="@/assets/images/main/center/yiqi.png"
              alt=""
            />
          </div>
          <div
            class="center-main-btn right-center-centerB-btn"
            @click="toCenterPage('zhineng')"
            @mousemove="hoverCenterImg('7')"
            @mouseleave="leaveCenterImg"
          >
            <div class="text-btn right-text-btn" :class="{'select-btn':currentCenterSelect == 'zhineng'}">智能报告</div>
            <img
              v-if="centerUrlType == '7' || currentCenterSelect == 'zhineng'"
              class="btn-img"
              src="@/assets/images/main/center/h-zhineng.png"
              alt=""
            />
            <img
              v-else
              class="btn-img"
              src="@/assets/images/main/center/zhineng.png"
              alt=""
            />
          </div>
          <div
            class="center-main-btn right-center-bottom-btn"
            @click="toCenterPage('zhaoshang')"
            @mousemove="hoverCenterImg('8')"
            @mouseleave="leaveCenterImg"
          >
            <div class="text-btn right-text-btn" :class="{'select-btn':currentCenterSelect == 'zhaoshang'}">招商入园</div>
            <img
              v-if="centerUrlType == '8' || currentCenterSelect == 'zhaoshang'"
              class="btn-img"
              src="@/assets/images/main/center/h-zhaoshang.png"
              alt=""
            />
            <img
              v-else
              class="btn-img"
              src="@/assets/images/main/center/zhaoshang.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-container">
      <div class="btn-container">
        <div
          class="img-container"
          @click="toPage('shuju')"
          @mousemove="hoverBottomImg('1')"
          @mouseleave="leaveBottomImg('3')"
        >
          <img
            v-if="urlType == '1' || currentSelect == 'shuju'"
            class="touming-img"
            src="@/assets/images/main/bottom/h-shuju.png"
            alt=""
          />
          <img
            v-else
            class="touming-img"
            src="@/assets/images/main/bottom/shuju.png"
            alt=""
          />
        </div>
        <div
          class="img-container"
          @click="toPage('gis')"
          @mousemove="hoverBottomImg('2')"
          @mouseleave="leaveBottomImg('3')"
        >
          <img
            v-if="urlType == '2' || currentSelect == 'gis'"
            class="touming-img"
            src="@/assets/images/main/bottom/h-gis.png"
            alt=""
          />
          <img
            v-else
            class="touming-img"
            src="@/assets/images/main/bottom/gis.png"
            alt=""
          />
        </div>
        <div
          class="img-container"
          @click="toPage('xitong')"
          @mousemove="hoverBottomImg('3')"
          @mouseleave="leaveBottomImg('3')"
        >
          <img
            v-if="urlType == '3' || currentSelect == 'xitong'"
            class="touming-img"
            src="@/assets/images/main/bottom/h-xitong.png"
            alt=""
          />
          <img
            v-else
            class="touming-img"
            src="@/assets/images/main/bottom/xitong.png"
            alt=""
          />
        </div>
      </div>
      <img class="dibu-img" src="@/assets/images/main/bottom/dibu.png" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  name: "Main",
  data() {
    return {
      hideBottomFir: false,
      hideBottomSec: false,
      hideBottomThird: false,
      currentSelect: "",
      rememberCurrent: "",
      urlType: "",

      rememberCenterCurrent: "",
      currentCenterSelect: "",
      centerUrlType: "",
    };
  },
  methods: {
    hoverBottomImg(urlType) {
      this.urlType = urlType;
      // this.currentSelect = ''
    },
    leaveBottomImg() {
      this.urlType = "";
      // this.currentSelect = rememberCurrent
    },
    toPage(selectType) {
      this.currentSelect = selectType;
      this.rememberCurrent = selectType;
    },
    toCenterPage(selectType) {
      this.currentCenterSelect = selectType;
      this.rememberCenterCurrent = selectType;
    },
    hoverCenterImg(urlType) {
      this.centerUrlType = urlType;
    },
    leaveCenterImg() {
      this.centerUrlType = "";
    },
  },
};
</script>
<style lang="less" scoped>
.main {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  .bg-img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  // background-size: 100% 100%;
  // background: url("../assets/images/main/center/ditu.png");
  // background-repeat: no-repeat;
  .center-container {
    margin-top: 0.625rem;
    position: relative;
    width: 100%;
    height: 54.625rem;
    display: flex;
    justify-content: center;
    // flex-grow: 1;
    .center-main {
      display: flex;
      align-items: center;
      justify-content: center;
      .center-img {
        height: 46.6875rem;
        width: 46.875rem;
        // height: 100%;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .left,
      .right {
        position: relative;
        height: 100%;
        width: 8.75rem;
        .btn-img {
          height: 8.75rem;
          width: 8.75rem;
          img {
            height: 100%;
            width: 100%;
          }
        }
      }
      .center-main-btn {
        position: absolute;
        display: flex;
        align-items: center;
        cursor: pointer;
        //  margin-bottom: 96px;
        &.left-center-top-btn {
          top: 3.125rem;
          right: -3.125rem;
        }
        &.left-center-centerA-btn {
          bottom: 54%;
          //  margin-top: -70px;
          right: 3.125rem;
        }
        &.left-center-centerB-btn {
          top: 54%;
          //  margin-top: -70px;
          right: 3.125rem;
        }
        &.left-center-bottom-btn {
          bottom: 3.125rem;
          right: -3.125rem;
        }
        &.right-center-top-btn {
          top: 3.125rem;
          left: -3.125rem;
        }
        &.right-center-centerA-btn {
          //  top:50%;
          //  margin-top: -70px;
          left: 3.125rem;
          bottom: 54%;
        }
        &.right-center-centerB-btn {
          //  top:50%;
          //  margin-top: -70px;
          top: 54%;
          left: 3.125rem;
        }
        &.right-center-bottom-btn {
          bottom: 3.125rem;
          left: -3.125rem;
        }
        .text-btn {
          // font-family: Source Han Sans CN;
          font-size: 1.125rem;
          font-weight: 600;
          color: #ffffff;
          position: absolute;
          width: 15rem;
          height: 4rem;
          letter-spacing: 0.125rem;
          text-align: center;
          line-height: 4rem;
          left: -16rem;

          &.right-text-btn {
            left: 10rem;
          }
          background: linear-gradient(
            90deg,
            rgba(21, 154, 255, 0) 0%,
            rgba(21, 154, 255, 0.99) 49%,
            rgba(21, 154, 255, 0) 100%
          );
           &.select-btn{
            background: linear-gradient(
              90deg,
              rgba(240, 222, 75, 0) 0%,
              rgba(240, 222, 75, 0.99) 49%,
              rgba(240, 222, 75, 0) 100%
            );
          }
        }
         &:hover .text-btn{
            background: linear-gradient(
              90deg,
              rgba(240, 222, 75, 0) 0%,
              rgba(240, 222, 75, 0.99) 49%,
              rgba(240, 222, 75, 0) 100%
            );
          }
      }
    }
    .img-container {
      width: 117.5rem;
      height: 54.625rem;
      position: absolute;
      top: 0;
      z-index: -1;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .top-container {
    width: 100%;
    height: 5rem;
    .main-bg {
      width: 100%;
      // height: 80px;
      img {
        width: 100%;
        // height: 100%;
      }
    }
  }
  .bottom-container {
    width: 100%;
    margin-top: 3.125rem;
    position: relative;
    // display: flex;
    // justify-content: center;
    .dibu-img {
      width: 100%;
      height: 4.25rem;
      // position: absolute;
      // bottom: 0;
      // left: 0;
    }
    .btn-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 61.25rem;
      height: 4.25rem;
      position: absolute;
      left: 50%;
      margin-left: -30.625rem;
      top: -1.875rem;
      .img-container {
        cursor: pointer;
        width: 15.8125rem;
        height: 4.25rem;
        img {
          width: 100%;
        }
      }
    }
  }
}
</style>