<template>
  <div class="ChartsB">
    <div class="main-chart-container">
      <div class="left-container">
        <div class="left-top">
          <chart-common
            :showSelect="true"
            :optionsData="gdpOptions"
            @getSelectData="getSelectData_gdp"
          >
            <div class="main-special-chart">
              <div class="top-count-container">
                <div class="left-chart-container">
                  <div class="img-icon-container">
                    <img src="@/assets/images/charts/tongji.png" alt="" />
                    <span>GDP</span>
                  </div>
                  <div class="text-container">
                    <span>{{ gdpForm.money }}</span>
                    亿元
                  </div>
                </div>
                <div class="right-chart-container">
                  <div class="left-text-container">
                    <div class="top-container">
                      <span>增长</span>
                      <i></i>
                    </div>
                    <div class="bottom-container">
                      <span>{{ gdpForm.percent }}%</span>
                      <img
                        class="arrow"
                        src="@/assets/images/charts/redarrow.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="chart-container">
                <lineChart
                  class="GDP-chart"
                  :echartId="'GDP-chart'"
                  :dataXAis="[
                    '2021第一季度',
                    '2021第二季度',
                    '2021第三季度',
                    '2021第四季度',
                    '2022第一季度'
                  ]"
                  :dataList="['189.87', '189.36', '219.5', '311.87', '220.07']"
                ></lineChart>
              </div>
            </div>
          </chart-common>
        </div>
        <div class="left-center">
          <div class="left-center-left">
            <chart-common :isShowHeader="false">
              <p class="yearTitle">2021年度</p>
              <div class="left-center-left">
                <div class="chart-bottom-item-container">
                  <!-- <img src="@/assets/images/charts/huang.png" alt=""> -->
                  <div class="bottom-chart">
                    <pieDChart
                      class="pieD-chart"
                      :optionsTitle="'129.6 亿元'"
                      :echartId="'pieD-chart'"
                    ></pieDChart>
                  </div>
                  <span class="chart-bottom-label-text"
                    >43.5%
                    <img
                      class="arrow"
                      src="@/assets/images/charts/redarrow.png"
                      alt=""
                    />
                  </span>
                  <span class="label-bottom">工业投资</span>
                </div>
                <div
                  class="chart-bottom-item-container"
                  :style="{
                    'background-image':
                      'url(' + require('../assets/images/charts/lv.png') + ')'
                  }"
                >
                  <!-- <img src="@/assets/images/charts/huang.png" alt=""> -->
                  <div class="bottom-chart">
                    <pieDChart
                    class="pieD-chart"
                      :echartId="'pieDc-chart'"
                      :colorArr="['#3BE8FF', '#36EDB5']"
                      :uploadedDataURL="
                        require('@/assets/images/charts/lv_dot.png')
                      "
                      :nodataColor="['#389098', '#388b96']"
                      :optionsTitle="'89.9 亿元'"
                    ></pieDChart>
                  </div>
                  <span class="chart-bottom-label-text"
                    >81.3%
                    <img
                      class="arrow"
                      src="@/assets/images/charts/redarrow.png"
                      alt=""
                    />
                  </span>
                  <span class="label-bottom">技改投资</span>
                </div>
              </div>
            </chart-common>
          </div>
          <div class="left-center-right">
            <chart-common :isShowHeader="false">
              <div class="yellow-img">
                <img src="@/assets/images/charts/yellow_dot.png" alt="" />
                <div class="text-container">
                  <span>园区</span>
                  <span class="num">799<i>个</i> </span>
                </div>
              </div>
            </chart-common>
          </div>
        </div>
        <div class="left-bottom">
          <chart-common
            :title="'重大项目'"
            :showSelect="true"
            :optionsData="zdProOptions"
            @getSelectData="getSelectData_zdPro"
          >
            <div class="main-program">
              <div class="program-left">
                <img src="@/assets/images/charts/tuopan.png" alt="" />
                <div class="program-text">
                  <span class="num">{{ zdProForm.project }} <i>个</i></span>
                  <span class="desc">重大项目</span>
                </div>
              </div>
              <div class="program-right">
                <div class="program-data">
                  <span class="data-label">总投资</span>
                  <span>{{ zdProForm.totalMoney }}<i>亿元</i> </span>
                </div>
                <div class="program-data">
                  <span class="data-label">年度计划投资约</span>
                  <span>{{ zdProForm.planMoney }}<i>亿元</i> </span>
                </div>
              </div>
            </div>
          </chart-common>
        </div>
      </div>
      <div class="center-container">
        <div class="center-top">
          <chart-common :title="'高新企业数量统计'">
            <div class="main-special-chart">
              <div class="top-count-container">
                <div class="left-chart-container">
                  <div class="img-icon-container">
                    <img src="@/assets/images/charts/tongji.png" alt="" />
                    <span>2021国家高新技术企业</span>
                  </div>
                  <div class="text-container">
                    <span>{{ gxForm.num }}</span>
                    家
                  </div>
                </div>
                <div class="right-chart-container">
                  <div class="left-text-container">
                    <div class="top-container">
                      <span>增长</span>
                      <i></i>
                    </div>
                    <div class="bottom-container">
                      <span>{{ gxForm.percent }}%</span>
                      <img
                        class="arrow"
                        src="@/assets/images/charts/redarrow.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="chart-container bar-chart-container">
                <barChart
                class="bar-chart"
                  :dataList="[197, 397, 686]"
                  :rotateEdg="'0'"
                  :echartId="'bar-chart'"
                  :dataAxis="['2019年', '2020年', '2021年']"
                  :chartColor="['#149AFE', '#1766a0', '#149AFE']"
                  @showModal="initModal"
                ></barChart>
              </div>
            </div>
          </chart-common>
        </div>
        <div class="center-center">
          <chart-common
            :title="'专精特新企业数量统计'"
            :showSelect="true"
            :optionsData="zjtxOptions"
          >
            <div class="center-chart">
              <pieCChart
              class="zjtx-chart"
                :showLegend="false"
                :echartId="'pieC-chart'"
                @changeLines="changeLines"
              ></pieCChart>
            </div>
            <div class="desc-container">
              <span>国家级专精特新小巨人企业</span>
              <span>广东省专精特新企业</span>
            </div>
          </chart-common>
        </div>
        <div class="center-bottom">
          <chart-common
            :title="'专利'"
            :showSelect="true"
            :optionsData="zlOptions"
            @getSelectData="getSelectData_zl"
          >
            <div class="main-special-chart">
              <div class="top-count-container">
                <div class="left-chart-container">
                  <div class="img-icon-container">
                    <img src="@/assets/images/charts/tongji.png" alt="" />
                    <span>专利</span>
                  </div>
                  <div class="text-container">
                    <span>{{ zlForm.num }}</span>
                    个
                  </div>
                </div>
                <div class="right-chart-container">
                  <div class="left-text-container">
                    <div class="top-container">
                      <span>增长</span>
                      <i></i>
                    </div>
                    <div class="bottom-container">
                      <span>{{ zlForm.percent }}%</span>
                      <img
                        class="arrow"
                        src="@/assets/images/charts/redarrow.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="chart-container bar-chart-container progress-chart-container"
              >
                <div class="progress">
                  <div class="year">2019</div>
                  <div class="barTotal">
                    <div
                      class="barActived"
                      :style="`width:${(500 / 1000) * 100}%`"
                    >
                      <img
                        src="../assets/images/charts/progress-circle.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="total-num">5776</div>
                </div>
                <div class="progress">
                  <div class="year">2020</div>
                  <div class="barTotal">
                    <div
                      class="barActived"
                      :style="`width:${(750 / 1000) * 100}%`"
                    >
                      <img
                        src="../assets/images/charts/progress-circle.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="total-num">8317</div>
                </div>
                <div class="progress">
                  <div class="year">2021</div>
                  <div class="barTotal">
                    <div
                      class="barActived"
                      :style="`width:${(800 / 1000) * 100}%`"
                    >
                      <img
                        src="../assets/images/charts/progress-circle.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="total-num">9275</div>
                </div>
              </div>
            </div>
          </chart-common>
        </div>
      </div>
      <div class="right-container">
        <div class="right-top">
          <chart-common :isShowHeader="false">
            <div class="doubleChart">
              <div class="top_chart">
                <div class="top_chart_left">
                  <img src="@/assets/images/charts/tuopan.png" alt="" />
                  <div class="info">
                    <div class="num">703 <span>家</span></div>
                    <div class="desc">规上工业</div>
                  </div>
                </div>
                <div class="top_chart_right">
                  <div class="title">最新数据</div>
                  <div class="table_head">
                    <div class="word">迁入</div>
                    <div class="word2">迁出</div>
                  </div>
                  <div class="table_cont">
                    <div class="word">
                      +5
                      <img
                        class="arrow"
                        src="@/assets/images/charts/redarrow.png"
                        alt=""
                      />
                    </div>
                    <div class="word2">
                      -1<img
                        class="arrow"
                        src="@/assets/images/charts/greenarrow.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="bott_chart">
                <div class="top_chart_left">
                  <img src="@/assets/images/charts/tuopan.png" alt="" />
                  <div class="info">
                    <div class="num">2395.2 <span>亿元</span></div>
                    <div class="desc">规上工业总产值</div>
                  </div>
                </div>
                <div class="top_chart_right">
                  <div class="title">
                    <div class="select-nav-container1">
                      <el-select
                        v-model="gs_value"
                        placeholder="请选择"
                        @change="getSelectData"
                      >
                        <el-option key="2021" label="2021年" :value="2021">
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                  <div class="table_head">
                    <div class="word">增长</div>
                  </div>
                  <div class="table_cont">
                    <div class="word">
                      17.4
                      <img
                        class="arrow"
                        src="@/assets/images/charts/redarrow.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </chart-common>
        </div>
        <div class="right-main-item">
          <chart-common
            :title="'规上工业增加值'"
            :showSelect="true"
            :optionsData="gsOptions"
            @getSelectData="getSelectData_gs"
          >
            <div class="right-main">
              <div class="left-chart-container">
                <div class="img-icon-container">
                  <img src="@/assets/images/charts/tongji.png" alt="" />
                </div>
                <div class="text-container">
                  <span>{{ gsForm.num }}</span>
                  亿元
                </div>
              </div>
              <div class="right-chart-container">
                <span>增长</span>
                <span
                  >{{ gsForm.percent }}%
                  <img
                    class="arrow"
                    src="@/assets/images/charts/redarrow.png"
                    alt=""
                /></span>
              </div>
              <div class="text-bg" v-if="gsForm.rank!==''">
                <span class="text" > 增速全市第{{gsForm.rank}} </span>
                <img
                  class="arrow"
                  src="@/assets/images/industryHousing/typeLeft_actived.png"
                  alt=""
                />
              </div>
            </div>
          </chart-common>
        </div>
        <div class="right-main-item">
          <chart-common
            :title="'固定资产投资总额'"
            :showSelect="true"
            :optionsData="gsOptions"
            @getSelectData="getSelectData_gd"
          >
            <div class="right-main">
              <div class="left-chart-container">
                <div class="img-icon-container">
                  <img src="@/assets/images/charts/tongji.png" alt="" />
                </div>
                <div class="text-container">
                  <span>{{ gdzcForm.num }}</span>
                  亿元
                </div>
              </div>
              <div class="right-chart-container">
                <span>增长</span>
                <span
                  >{{ gdzcForm.percent }}%
                  <img
                    class="arrow"
                    src="@/assets/images/charts/redarrow.png"
                    alt=""
                /></span>
              </div>
              <div class="text-bg">
                <span class="text"> 增速全市第{{gdzcForm.rank}} </span>
                <img
                  class="arrow"
                  src="@/assets/images/industryHousing/typeLeft_actived.png"
                  alt=""
                />
              </div>
            </div>
          </chart-common>
        </div>
        <div class="right-main-item">
          <chart-common
            :title="'社会消费品零售总额'"
            :showSelect="true"
            :optionsData="gsOptions"
            @getSelectData="getSelectData_xfp"
          >
            <div class="right-main">
              <div class="left-chart-container">
                <div class="img-icon-container">
                  <img src="@/assets/images/charts/tongji.png" alt="" />
                </div>
                <div class="text-container">
                  <span>{{ shxfForm.num }}</span>
                  亿元
                </div>
              </div>
              <div class="right-chart-container">
                <span>增长</span>
                <span
                  >{{ shxfForm.percent }}
                  <img
                    class="arrow"
                    src="@/assets/images/charts/redarrow.png"
                    alt=""
                /></span>
              </div>
              <div class="text-bg">
                <span class="text"> 增速全市第{{shxfForm.rank}} </span>
                <img
                  class="arrow"
                  src="@/assets/images/industryHousing/typeLeft_actived.png"
                  alt=""
                />
              </div>
            </div>
          </chart-common>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="centerDialogVisible"
      width="40%"
      height="40%"
      center
      :close-on-click-modal="false"
      @closed="initModalLineChart"
    >
      <div class="modal-line-chart">
        <div class="close-btn">
          <img
            @click="centerDialogVisible = false"
            src="@/assets/images/charts/x.png"
            alt=""
          />
        </div>
        <chart-common :title="'低碳企业数量趋势图'">
          <lineBChart
            :dataList="modalLineChartDataList"
            :echartId="'line-chart'"
            ref="modalLineChart"
          ></lineBChart>
        </chart-common>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import chartCommon from '@/components/chartCommon/index'
import barChart from '@/components/charts/bar'
import pieCChart from '@/components/charts/pieC'
import pieDChart from '@/components/charts/pieD'
import lineChart from '@/components/charts/line'
import lineBChart from '@/components/charts/lineB'
import linesChart from '@/components/charts/lines'
import progress from '@/components/charts/progress'
export default {
  components: {
    chartCommon,
    barChart,
    pieCChart,
    lineChart,
    lineBChart,
    linesChart,
    pieDChart,
    progress
  },
  name: 'Charts',
  data () {
    return {
      gs_value: 2021,
      modalLineChartDataList:[],
      zlForm: {
        num: '9275',
        percent: '21.9'
      },
      gxForm: {
        num: '686',
        percent: '25'
      },
      gdpForm: {
        money: '910.6',
        percent: '11.7'
      },
      gsForm: {
        num: '116.5',
        percent: '22',
        rank:'一'
      },
      gdzcForm: {
        num: '84.83',
        percent: '40.1',
        rank:'一'
      },
      shxfForm: {
        num: '39.91',
        percent: '13.3',
        rank:'一'
      },
      centerDialogVisible: false,
      gsOptions: [
        {
          label: '2022年第一季度',
          value: 1
        },
        {
          label: '2021年度',
          value: 2
        }
      ],
      zjtxOptions: [
        {
          label: '2021年度',
          value: 1
        }
      ],
      zlOptions: [
        {
          label: '2021年度',
          value: 1
        },
        {
          label: '2020年度',
          value: 2
        },
        {
          label: '2019年度',
          value: 3
        }
      ],
      gdpOptions: [
        {
          label: '2021年总产值',
          value: 1
        },
        {
          label: '2022年第一季度',
          value: 2
        }
      ],
      zdProForm: {
        project: '15',
        totalMoney: '51.1',
        planMoney: '13.2'
      },
      zdProOptions: [
        {
          label: '2022年第一季度',
          value: 2
        },
        {
          label: '2022年第二季度',
          value: 1
        }
      ]
    }
  },
  mounted () {},
  methods: {
    changeLines() {},
    getSelectData_gs (val) {
      if (val == 1) {
        this.gsForm = {
          num: '116.5',
          percent: '22',
          rank:'一'
        }
      } else {
        this.gsForm = {
          num: '485.1',
          percent: '12.1',
          rank:''
        }
      }
    },
    getSelectData_gd (val) {
      if (val == 1) {
        this.gdzcForm = {
          num: '84.83',
          percent: '40.1',
          rank:'一'
        }
      } else {
        this.gdzcForm = {
          num: '481.5',
          percent: '15.9',
          rank:'三'
        }
      }
    },
    getSelectData_xfp (val) {
      if (val == 1) {
        this.shxfForm = {
          num: '39.91',
          percent: '13.3',
          rank:'一'
        }
      } else {
        this.shxfForm = {
          num: '181.2',
          percent: '19.2',
          rank:'二'
        }
      }
    },
    getSelectData_zl (val) {
      if (val == 1) {
        this.zlForm = {
          num: 9275,
          percent: '21.9'
        }
      } else if (val == 2) {
        this.zlForm = {
          num: '8317',
          percent: '--'
        }
      } else if (val == 3) {
        this.zlForm = {
          num: '5776',
          percent: '--'
        }
      }
    },
    getSelectData_gdp (val) {
      if (val == 1) {
        this.gdpForm = {
          money: '910.6',
          percent: '11.7'
        }
      } else {
        this.gdpForm = {
          money: '220.07',
          percent: '9.6'
        }
      }
    },
    getSelectData_zdPro (val) {
      if (val == 1) {
        //22年第2季度
        this.zdProForm = {
          project: '15',
          totalMoney: '51.1',
          planMoney: '13.2'
        }
      } else {
        this.zdProForm = {
          project: '23',
          totalMoney: '172.5097',
          planMoney: '37.1755'
        }
      }
    },
    legendSelect (chart, item) {},
    // initModal (lineChartSearchQuery) {
    //   console.log(lineChartSearchQuery)
    //   this.modalLineChartDataList = []
    //   for (var i = 0; i < 8; i++) {
    //     this.modalLineChartDataList.push(Math.floor(Math.random(100) * 500))
    //   }
    //   this.centerDialogVisible = true
    // },
    initModal (params) {
      if (params.name == '2021年') {
        params.percent = '25'
      } else {
        params.percent = '--'
      }
      this.gxForm = {
        num: params.value,
        percent: params.percent
      }
    },
    initModalLineChart () {
      this.$refs.modalLineChart.destroyeChart()
    }
  }
}
</script>
<style lang="less" scoped>
.ChartsB {
  height: 100%;
  padding: 1.25rem;
  .main-chart-container {
    display: flex;
    justify-content: space-between;
    // flex-wrap: wrap;
    .left-container {
      width: 43.25rem;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .left-top {
        width: 100%;
        height: 23.75rem;
      }
      .left-center {
        width: 100%;
        height: 15.625rem;
        margin: 1.25rem 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left-center-left {
          width: 26.625rem;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: space-around;
          .yearTitle {
            position: absolute;
            right: 1.25rem /* 20/16 */;
            top: .625rem /* 10/16 */;
            color: #fff;
          }
          .chart-bottom-item-container {
            position: relative;
            height: 10.75rem;
            width: 8.125rem;
            background-image: url('../assets/images/charts/lan.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
            .bottom-chart {
              height: 100%;
              width: 100%;
            }
            .chart-bottom-label-text {
              position: absolute;
              width: 100%;
              text-align: center;
              bottom: 2rem;
              font-size: 1rem;
              // font-family: Source Han Sans CN;
              font-weight: bold;
              color: #d0deee;
              cursor: pointer;
              // display: flex;
              // align-items: center;
            }
            .label-bottom {
              position: absolute;
              width: 100%;
              text-align: center;
              bottom: -1.625rem;
              font-size: 1rem;
              // font-family: Source Han Sans CN;
              font-weight: bold;
              color: #d0deee;
              cursor: pointer;
            }
          }
        }
        .left-center-right {
          width: 15.375rem;
          height: 100%;
          position: relative;
          .yellow-img {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              height: 10rem;
              width: 10rem;
            }
            .text-container {
              position: absolute;
              height: 100%;
              width: 100%;
              display: flex;
              justify-content: center;
              flex-direction: column;
              color: #ffffff;
              align-items: center;
              span {
                font-size: 1.125rem;
                font-weight: bold;
                color: #ffffff;
                &.num {
                  font-size: 1.75rem;
                  font-weight: 400;
                  color: #ffffff;
                  margin-top: 0.3125rem;
                }
              }
              i {
                font-style: normal;
                font-size: 0.875rem;
                font-weight: 400;
              }
            }
          }
        }
      }
      .left-bottom {
        width: 100%;
        height: 17.5rem;
        .main-program {
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          .program-left {
            margin-left: 1.25rem;
            margin-right: 3.125rem;
            width: 12.125rem;
            height: 100%;
            position: relative;
            display: flex;
            align-items: flex-end;

            .program-text {
              height: 100%;
              top: 0;
              left: 0;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              flex-direction: column;
              z-index: 11;
              padding-bottom: 5.125rem;
              .num {
                font-size: 1.75rem;
                font-weight: 700;
                color: #ffffff;
              }
              .desc {
                font-size: 1.125rem;
                font-weight: 400;
                color: #ffffff;
              }
              i {
                font-style: normal;
                font-size: 0.875rem;
                font-weight: 400;
                color: #ffffff;
              }
            }
            img {
              width: 12.125rem;
              height: 10.875rem;
            }
          }
          .program-right {
            display: flex;
            flex-direction: column;
            .program-data {
              display: flex;
              flex-direction: column;
              margin-bottom: 1.25rem;
              &:last-child {
                margin-bottom: 0;
              }
              span {
                font-size: 1.25rem;
                font-weight: 400;
                color: #ffffff;
                padding-left: 1.25rem;
                i {
                  font-style: normal;
                  height: 14px;
                  font-size: 0.875rem;
                  font-weight: 400;
                  color: #ffffff;
                }
              }
              .data-label {
                width: 18.375rem;
                height: 1.9375rem;
                display: flex;
                align-items: center;
                padding-left: 1.25rem;
                background: url('../assets/images/industryMap/left/03@2x.png');
                background-repeat: no-repeat;
                background-size: 100% 100%;
                font-size: 1rem;
                font-weight: bold;
                color: #ffffff;
                margin-bottom: 0.3125rem;
              }
            }
          }
        }
      }
    }
    .center-container {
      width: 33.25rem;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .center-top {
        width: 33.25rem;
        height: 23.75rem;
      }
      .center-center {
        width: 100%;
        height: 15.625rem;
        margin: 1.25rem 0;
        .center-chart {
          // height: 100%;
          height: 100%;
          width: 100%;
          position: relative;
        }
        .desc-container {
          position: absolute;
          bottom: 0.625rem;
          width: 100%;
          height: 2.5rem;
          line-height: 2.5rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 1rem;
          font-weight: bold;
          color: #ffffff;
          span {
            width: 50%;
            text-align: center;
          }
        }
      }
      .center-bottom {
        width: 100%;
        height: 17.5rem;
      }
    }
    .right-container {
      width: 38.5rem;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .right-top {
        width: 100%;
        height: 21.875rem /* 350/16 */;
        .doubleChart {
          padding: 1.25rem /* 20/16 */;
          height: 100%;
          .top_chart,
          .bott_chart {
            height: 45%;
            display: flex;
            .top_chart_left {
              width: 40%;
              display: flex;
              position: relative;
              img {
                width: 12.125rem /* 194/16 */;
                height: 10.875rem /* 174/16 */;
              }
              .info {
                position: absolute;
                text-align: center;
                width: 12.125rem /* 194/16 */;
                height: 10.875rem /* 174/16 */;
                align-items: center;
                display: flex;
                flex-direction: column;
                padding-top: 0.875rem /* 30/16 */;
                .num {
                  font-size: 1.75rem /* 28/16 */;
                  font-weight: 400;
                  color: #ffffff;
                  span {
                    font-style: normal;
                    font-size: 0.875rem;
                    font-weight: 400;
                    color: #ffffff;
                  }
                }
                .desc {
                  font-size: 1.125rem;
                  font-weight: 400;
                  color: #ffffff;
                }
              }
            }
            .top_chart_right {
              padding-top: 1.25rem /* 20/16 */;
              .title {
                font-size: 1rem;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #ffffff;
              }
              .table_head {
                margin-top: 1.1875rem /* 19/16 */;
                width: 18.375rem;
                height: 1.9375rem;
                display: flex;
                align-items: center;
                padding-left: 1.25rem;
                background: url('../assets/images/industryMap/left/03@2x.png');
                background-repeat: no-repeat;
                background-size: 100% 100%;
                font-size: 1rem;
                font-weight: bold;
                color: #ffffff;
                margin-bottom: 0.3125rem;
                .word2 {
                  margin-left: 7.375rem /* 118/16 */;
                }
              }
              .table_cont {
                width: 18.375rem;
                height: 1.9375rem;
                display: flex;
                align-items: center;
                padding-left: 0.25rem;
                font-size: 1.75rem /* 28/16 */;
                font-family: PangMenZhengDao;
                font-weight: 400;
                color: #ffffff;
                .word {
                  display: flex;
                  align-items: center;
                  img {
                    width: 0.375rem;
                    height: 1rem;
                    margin-left: 0.625rem;
                  }
                }
                .word2 {
                  display: flex;
                  margin-left: 7.375rem /* 118/16 */;
                  align-items: center;
                  img {
                    width: 0.375rem;
                    height: 1rem;
                    margin-left: 0.625rem;
                  }
                }
              }
            }
          }
        }
      }
      .right-main-item {
        width: 100%;
        height: 11.3125rem;
        margin-top: 1.1875rem;
        .right-main {
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          position: relative;

          .left-chart-container {
            margin-left: 2.75rem;
            margin-right: 3.25rem;
            background: rgba(86, 130, 163, 0.5);
            // border: 1px solid #159aff;
            // box-shadow: 0px 0px 16px 0px #159aff inset;
            width: 17.4375rem;
            height: 5.625rem;
            display: flex;
            align-items: center;
            justify-content: space-around;
            padding: 0 1.25rem;
            border-left: 2px solid #3be8ff;
            border-right: 2px solid #3be8ff;
            .img-icon-container {
              display: flex;
              align-items: center;
              font-size: 0.875rem;
              font-weight: bold;
              color: #ffffff;
              img {
                height: 3.25rem;
                width: 3.25rem;
              }
            }
            .text-container {
              font-size: 0.875rem;

              // font-family: Source Han Sans CN;
              font-weight: 400;
              color: #d0deee;
              span {
                font-size: 2.25rem;
                // font-family: PangMenZhengDao;
                font-weight: 600;
                color: #3be8ff;
              }
            }
          }
          .right-chart-container {
            width: 10.3125rem;
            height: 2.625rem;
            background: rgba(86, 130, 163, 0.5);
            display: flex;
            justify-content: space-around;
            align-items: center;
            span {
              font-size: 1.125rem;
              font-weight: 400;
              color: #fffbf1;
              &:first-child {
                font-size: 1rem;
                font-weight: 400;
                color: #d0deee;
              }
            }
          }
          .text-bg {
            position: absolute;
            right: 0;
            bottom: 0;
            // width: 5.625rem;
            height: 1.875rem;
            width: 8.375rem /* 134/16 */;

            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 100%;
              height: 100%;
            }
            .text {
              font-size: 0.75rem;
              font-weight: 400;
              color: #f0de4b;
              width: 100%;
              height: 100%;
              position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
  }
  .main-special-chart {
    padding-top: 1.25rem;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    .chart-container {
      flex-grow: 1;
      width: 100%;
      display: flex;
      align-items: center;
      &.bar-chart-container {
        padding: 1.25rem;
        .progress {
          display: flex;
          align-items: center;
          margin-bottom: 2.125rem /* 34/16 */;
          .year,
          .total-num {
            font-size: 0.875rem /* 14/16 */;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
          }
          .barTotal {
            margin: 0 0.625rem /* 10/16 */;
            width: 24.125rem /* 386/16 */;
            height: 8px;
            background: #2b4c63;
            .barActived {
              position: relative;
              display: flex;
              align-items: center;
              // width:40%;
              height: 8px;
              background: linear-gradient(90deg, #3be8fe 0%, #36edb6 100%);
              img {
                position: absolute;
                right: -11px;
              }
            }
          }
        }
      }
      &.program {
        display: flex;
        align-items: center;
        justify-content: center;
        .program-left {
          margin-left: 1.25rem;
          margin-right: 3.125rem;
          width: 12.125rem;
          height: 100%;
          position: relative;
          display: flex;
          align-items: flex-end;

          .program-text {
            height: 100%;
            top: 0;
            left: 0;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            flex-direction: column;
            z-index: 11;
            padding-bottom: 3.125rem;
            .num {
              font-size: 1.75rem;
              font-weight: 700;
              color: #ffffff;
            }
            .desc {
              font-size: 1.125rem;
              font-weight: 400;
              color: #ffffff;
            }
            i {
              font-style: normal;
              font-size: 0.875rem;
              font-weight: 400;
              color: #ffffff;
            }
          }
          img {
            width: 12.125rem;
            height: 10.875rem;
          }
        }
        .program-right {
          display: flex;
          flex-direction: column;
          .program-data {
            display: flex;
            flex-direction: column;
            margin-bottom: 1.25rem;
            &:last-child {
              margin-bottom: 0;
            }
            span {
              font-size: 1.25rem;
              font-weight: 400;
              color: #ffffff;
              padding-left: 1.25rem;
              i {
                font-style: normal;
                height: 14px;
                font-size: 0.875rem;
                font-weight: 400;
                color: #ffffff;
              }
            }
            .data-label {
              width: 18.375rem;
              height: 1.9375rem;
              display: flex;
              align-items: center;
              padding-left: 1.25rem;
              background: url('../assets/images/industryMap/left/03@2x.png');
              background-repeat: no-repeat;
              background-size: 100% 100%;
              font-size: 1rem;
              font-weight: bold;
              color: #ffffff;
              margin-bottom: 0.3125rem;
            }
          }
        }
      }
      .chart-main-container {
        width: 50%;
        height: 100%;
      }

    }
    .progress-chart-container {
      display: flex;
      flex-direction: column;
      // padding: 0 1.25rem!important;
    }
  }
  .GDP-chart {
        width: 43.125rem /* 690/16 */;
        height: 16.25rem /* 260/16 */;
      }
      .bar-chart {
        width: 30.5rem /* 488/16 */;
        height: 13.6875rem /* 219/16 */;
      }
      .pieD-chart {
        height: 10.75rem;
    width: 8.125rem;
      }
      .zjtx-chart {
        width: 33.125rem /* 530/16 */;
    height: 12.625rem /* 202/16 */;
      }
}
//公共模块
.top-count-container {
  padding: 0 1.25rem;
  display: flex;
  height: 3.25rem;
  .left-chart-container {
    background: rgba(86, 130, 163, 0.5);
    // border: 1px solid #159aff;
    // box-shadow: 0px 0px 16px 0px #159aff inset;
    height: 100%;
    width: 22.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.25rem;
    border-left: 2px solid #3be8ff;
    border-right: 2px solid #3be8ff;
    .img-icon-container {
      display: flex;
      align-items: center;
      font-size: 0.875rem;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
      img {
        height: 3.25rem;
        width: 3.25rem;
      }
    }
    .text-container {
      font-size: 0.875rem;
      // font-family: Source Han Sans CN;
      font-weight: 400;
      color: #d0deee;
      span {
        font-size: 1.25rem;
        // font-family: PangMenZhengDao;
        font-weight: 400;
        color: #3be8ff;
      }
    }
  }
  .right-chart-container {
    display: flex;
    align-items: center;
    .left-text-container,
    .right-text-container {
      width: 6.5625rem;
      margin-left: 0.625rem;
      display: flex;
      flex-direction: column;
      .top-container {
        position: relative;
        display: flex;
        align-items: center;
        span {
          font-size: 0.8125rem;
          // font-family: Microsoft YaHei;
          font-weight: 400;
          color: #d0deee;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1;
        }
        i {
          width: 100%;
          height: 0.375rem;
          background: #5682a3;
          opacity: 0.5;
        }
      }
      .bottom-container {
        height: 1.75rem;
        background: rgba(86, 130, 163, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0.625rem;
        span {
          font-size: 1rem;
          // font-family: PangMenZhengDao;
          font-weight: 400;
          color: #fffbf1;
        }
        img {
          width: 0.375rem;
          height: 1rem;
          margin-left: 0.625rem;
        }
      }
    }
  }
  


}
</style>
<style lang="less">
.ChartsB {
  .el-dialog__header {
    display: none !important;
  }
  .el-dialog {
    background: rgba(15, 55, 83, 0.9);
  }
  .el-dialog__body {
    padding: 0;
  }
}
</style>
